import type { GridCellParams } from "@mui/x-data-grid";
import { Filters } from "consts/consumers";
import { useState, type ChangeEvent } from "react";
import type { TableProps } from "ui/widgets/Table";
import { useSimpleRequest } from "hooks/useRequest";
import { updateConsumer } from "features/consumers/api";
import { compareIds } from "util/support";
import { convertToBooleanSwitch } from "util/transformers";
import type { KYCProcessingStatusCellProps } from "ui/widgets/Table/renderers/KYCProcessingStatus";
import type { Consumer } from "features/consumers/types";
import { useConsumersActions } from "features/consumers/actions";
import type { Dispatch, SetStateAction } from "react";

export default function useCellProps(): Pick<TableProps, 'CellProps'> {
    const { updateConsumerById } = useConsumersActions();
    const [activeLoadingIdCollection, setActiveLoadingIdCollection] = useState<Array<string>>([]);
    const [forbiddenWordsLoadingIdCollection, setForbiddenWordsLoadingIdCollection] = useState<Array<string>>([]);

    const request = useSimpleRequest();

    const getSimpleSwitchCellProps = ({
        loadingIdCollection
    }: {
        readonly loadingIdCollection: Array<string>;
    }) => ({ row }: GridCellParams) => {
        if (loadingIdCollection.includes(row.coreId)) {
            return {
                loading: true,
                disabled: true
            };
        }

        return {
            loading: false,
            disabled: false
        };
    };

    const onSimpleSwitchChange = ({ getRequestBody, onSuccess, setLoadingIdCollection }: {
        readonly setLoadingIdCollection: Dispatch<SetStateAction<string[]>>;
        readonly getRequestBody?: (gridCellParams: GridCellParams, checked: boolean) => Partial<Consumer>;
        readonly onSuccess?: (gridCellParams: GridCellParams, checked: boolean) => void;
    }) => (gridCellParams: GridCellParams, _: ChangeEvent, checked: boolean) => {
        const { row, field } = gridCellParams;

        setLoadingIdCollection(state => [
            ...state,
            row.coreId
        ]);

        request(() => updateConsumer({
            coreId: row.coreId,
            MOR: row.MOR,
            [field]: convertToBooleanSwitch(checked),
            ...getRequestBody?.(gridCellParams, checked)
        }))
        .then(() => onSuccess?.(gridCellParams, checked))
        .finally(() => setLoadingIdCollection(state =>
            state.filter(coreId => !compareIds(coreId, row.coreId))
        ));
    };

    return {
        [Filters.hasImmunityForForbiddenWords]: {
            onChange: onSimpleSwitchChange({
                setLoadingIdCollection: setForbiddenWordsLoadingIdCollection,
                onSuccess: ({ row }, checked) =>
                    updateConsumerById({
                        coreId: row.coreId,
                        hasImmunityForForbiddenWords: convertToBooleanSwitch(checked)
                    })
            }),
            getCellProps: (props: GridCellParams) => ({
                ...getSimpleSwitchCellProps({
                    loadingIdCollection: forbiddenWordsLoadingIdCollection
                })(props),
                TooltipProps: {
                    title: props.value
                        ? 'Add to Forbidden Words check'
                        : 'Exclude from Forbidden Words check'
                }
            })
        },
        [Filters.active]: {
            onChange: onSimpleSwitchChange({
                setLoadingIdCollection: setActiveLoadingIdCollection,
                onSuccess: ({ row }, checked) =>
                    updateConsumerById({
                        coreId: row.coreId,
                        active: convertToBooleanSwitch(checked)
                    })
            }),
            getCellProps: getSimpleSwitchCellProps({
                loadingIdCollection: activeLoadingIdCollection
            }),
        },
        [Filters.kycStatusFace]: {
            getCellProps: ({ row }: GridCellParams): KYCProcessingStatusCellProps => ({
                title: row[Filters.kycStatusFaceReason],
                fileKey: row[Filters.kycStatusFaceFileKey]
            })
        },
        [Filters.kycStatusId]: {
            getCellProps: ({ row }: GridCellParams): KYCProcessingStatusCellProps => ({
                title: row[Filters.kycStatusIdReason],
                fileKey: row[Filters.kycStatusIdFileKey]
            })
        },
        [Filters.kycStatusAddress]: {
            getCellProps: ({ row }: GridCellParams): KYCProcessingStatusCellProps => ({
                title: row[Filters.kycStatusAddressReason],
                fileKey: row[Filters.kycStatusAddressFileKey]
            })
        },
        [Filters.kycStatusAml]: {
            getCellProps: ({ row }: GridCellParams): KYCProcessingStatusCellProps => ({
                title: row[Filters.kycStatusAmlReason],
                fileKey: row[Filters.kycStatusAmlFileKey]
            })
        },
        [Filters.kycStatusPhone]: {
            getCellProps: ({ row }: GridCellParams): KYCProcessingStatusCellProps => ({
                title: row[Filters.kycStatusPhoneReason],
                fileKey: row[Filters.kycStatusPhoneFileKey]
            })
        },
        [Filters.kycStatusEidv]: {
            getCellProps: ({ row }: GridCellParams): KYCProcessingStatusCellProps => ({
                title: row[Filters.kycStatusEidvReason],
                fileKey: row[Filters.kycStatusEidvFileKey]
            })
        }
    };
};
