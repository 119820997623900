import { addSeconds, isPast } from "date-fns";
import { ApiRoutes } from "consts/enpoints/api";
import { ACCESS_TOKEN_EXPIRY_THRESHOLD } from "consts/auth";
import { when } from "util/support";
import { convertToFormData } from "util/transformers";
import type { AuthResponse } from "features/auth/types";
import {
    getAuthData,
    getAuthToken,
    isResponseValid,
    revokeAuthData,
    setAuthData
} from "features/auth/helpers";
import {
    FormSerializers,
    type RequestBody,
    type ExtendedRequestInfo,
    type Middleware,
    type UrlString
} from "./types";

export const ApiStrategies = {
    [FormSerializers.FormData]: {
        getBody(body?: RequestBody) {
            return {
                body: when(
                    body instanceof FormData,
                    body,
                    () => body && convertToFormData(body)
                )
            };
        },

        getHeaders(headers = {}) {
            return {
                ...tokenHeaderMixin(),
                ...headers
            } as Record<string, string>;
        }
    },
    [FormSerializers.Json]: {
        getBody(body?: RequestBody) {
            return when(
                body,
                () => ({
                    body: JSON.stringify(body)
                }),
                {}
            );
        },

        getHeaders(headers = {}) {
            return {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                ...tokenHeaderMixin(),
                ...headers
            } as Record<string, string>;
        }
    }
};

export class RefreshToken {
    private static refreshTokenRequest: Promise<Response> | null = null;

    private static isExpired(expiryThreshold: number) {
        const authData = getAuthData();

        if (!authData) {
            return false;
        }

        const {
            expires_in: expiresIn,
            timestamp
        } = authData;

        return isPast(addSeconds(timestamp, (expiresIn / expiryThreshold)));
    }

    public static async refresh({
        baseUrl = process.env.REACT_APP_BACKEND_ORIGIN_URL,
        expiryThreshold = ACCESS_TOKEN_EXPIRY_THRESHOLD,
        force = false
    } = {}) {
        if (!force && !this.isExpired(expiryThreshold)) {
            return;
        }

        if (!this.refreshTokenRequest) {
            this.refreshTokenRequest = fetch(`${baseUrl}${ApiRoutes.PostRefreshToken}`, {
                method: 'post',
                headers: ApiStrategies[FormSerializers.Json].getHeaders(
                    tokenHeaderMixin()
                )
            });
        }

        const handleRevokeAuthData = () => {
            revokeAuthData();
            this.refreshTokenRequest = null;
        };
        const response = await this.refreshTokenRequest;
        let result: AuthResponse | null = null;

        if (response.ok) {
            result = (await response.json()) as AuthResponse;

            if (!isResponseValid(result)) {
                handleRevokeAuthData();
                throw result;
            }

            const { data } = result;

            setAuthData({
                ...data,
                timestamp: Date.now()
            });
        } else {
            handleRevokeAuthData();
            throw response;
        }

        this.refreshTokenRequest = null;

        return result;
    }
}

export const applyMiddleware = (middleware: Array<Middleware>): Middleware => (
    url: UrlString,
    requestInfo: ExtendedRequestInfo
) => {
    const {
        body,
        headers = {},
        getBody = ApiStrategies[FormSerializers.Json].getBody,
        getHeaders = ApiStrategies[FormSerializers.Json].getHeaders,
        ...restRequestInfo
    } = requestInfo;

    return middleware.reduce((acc, fn) => ({
        ...acc,
        ...fn(url, requestInfo)
    }), {
        ...restRequestInfo,
        ...getBody(body),
        headers: getHeaders(headers),
        url
    });
};

function tokenHeaderMixin() {
    const token = getAuthToken();
    if (token) {
        return {
            'Authorization': `Bearer ${token}`
        };
    }
    return {};
}
