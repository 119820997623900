import { memo } from "react";
import { TableGridMode } from "consts/table";
import { filterTypes } from "consts/merchantPayouts";
import MerchantPayoutsTable from "../MerchantPayoutsTable";
import useMerchantPayoutsActions from "features/merchantPayouts/useActions";

const IncrementsPivot = () => {
    const {
        getTransactionsMerchantPayoutIncrementsPivot
    } = useMerchantPayoutsActions();

    return (
        <MerchantPayoutsTable
            mode={TableGridMode.TransactionsMerchantPayoutIncrementsPivot}
            filterTypes={filterTypes}
            fetchTableData={getTransactionsMerchantPayoutIncrementsPivot}
        />
    );
};

export default memo(IncrementsPivot);
