import { memo, useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import useMerchantPayoutsActions from 'features/merchantPayouts/useActions';
import type { IncrementTaxes as MerchantPayoutIncrementTaxes } from "features/merchantPayouts/types";
import { TriggerableDialog } from 'ui/molecules/Dialog';
import useMerchantFeesAndCommisions from '../../../useMerchantFeesAndCommisions';

const DeleteFee = (merchantPayoutIncrementTaxes: MerchantPayoutIncrementTaxes) => {
    const {
        merchantName,
        taxName,
        tag,
        refColFrom,
        refColFromMinSum,
        currentIncMultiplierForEachRefColumn,
    } = merchantPayoutIncrementTaxes;

    const [isRequestProcessing, setRequestProcessingState] = useState(false);

    const { deleteMerchantPayoutIncrementTaxes } = useMerchantPayoutsActions();

    const { deleteMerchantFeesAndCommisions } = useMerchantFeesAndCommisions();

    return (
        <TriggerableDialog
            TriggerComponent={({ onClick }) => (
                <LoadingButton
                    size='small'
                    color='error'
                    variant='contained'
                    startIcon={(
                        <DeleteForeverIcon />
                    )}
                    loading={isRequestProcessing}
                    onClick={onClick}
                >
                    Delete
                </LoadingButton>
            )}
            DialogActionsComponent={({ onClose }) => (
                <>
                    <Button onClick={onClose}>
                        Close
                    </Button>
                    <LoadingButton
                        color='error'
                        variant='outlined'
                        onClick={async () => {
                            setRequestProcessingState(true);

                            const [request] = deleteMerchantPayoutIncrementTaxes(merchantPayoutIncrementTaxes);
                            await request;

                            deleteMerchantFeesAndCommisions(merchantPayoutIncrementTaxes);

                            onClose();

                            setRequestProcessingState(false);
                        }}
                        loading={isRequestProcessing}
                    >
                        Delete
                    </LoadingButton>
                </>
            )}
            ModalProps={{
                DialogProps: {
                    sx: {
                        '& .MuiDialog-container .MuiPaper-root': {
                            width: 'auto'
                        }
                    }
                },
                titleSlot: 'Are you sure you want to delete this row?'
            }}
        >
            <Box
                sx={{
                    textAlign: 'center'
                }}
            >

                {[
                    merchantName,
                    taxName,
                    tag,
                    refColFrom,
                    refColFromMinSum,
                    currentIncMultiplierForEachRefColumn
                ].join('-')}
            </Box>
        </TriggerableDialog>
    );
};

export default memo(DeleteFee);
