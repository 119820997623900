import { useMemo, useState } from 'react';
import { GridActionsCellItem, GridRowParams } from '@mui/x-data-grid-premium';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import type { AggregateRoot } from 'types';
import Icon from 'ui/atoms/Icon';
import { TriggerableDialog } from 'ui/molecules/Dialog';
import type { ModalProps } from 'ui/atoms/Modal';
import type { DeleteColumnActionProps } from './Props';

type Props<TEntity extends AggregateRoot> = GridRowParams & DeleteColumnActionProps<TEntity>;

const DeleteColumnAction = <TEntity extends AggregateRoot>({
    deleteActionRequestHandler,
    label,
    dialogProps,
    row,
    ...rest
}: Props<TEntity>) => {
    const [isLoading, setLoadingState] = useState(false);

    const TriggerComponent = useMemo(() =>
        ({ onClick }: IconButtonProps) => (
            <IconButton
                onClick={onClick}
            >
                <Icon name='Backspace' />
            </IconButton>
        ), []);

    const DialogActionsComponent = useMemo(() =>
        ({ onClose }: Pick<ModalProps, 'onClose'>) => {

            const handleDelete = async () => {
                setLoadingState(true);
                const [request] = deleteActionRequestHandler(row);
                await request;
                onClose();
                setLoadingState(false);
            };

            return (
                <>
                    <Button onClick={onClose}>
                        Close
                    </Button>
                    <LoadingButton
                        color='error'
                        variant='outlined'
                        onClick={handleDelete}
                        loading={isLoading}
                    >
                        Delete
                    </LoadingButton>
                </>
            );
        }, [row, deleteActionRequestHandler, isLoading]);

    const { children: Component } = dialogProps;

    return (
        <GridActionsCellItem
            label={label}
            icon={(
                <TriggerableDialog
                    TriggerComponent={TriggerComponent}
                    DialogActionsComponent={DialogActionsComponent}
                    ModalProps={{
                        DialogProps: {
                            sx: {
                                '& .MuiDialog-container .MuiPaper-root': {
                                    width: 'auto'
                                }
                            }
                        },
                        titleSlot: 'Are you sure you want to delete this row?',
                        ...dialogProps.ModalProps
                    }}
                >
                    <Box
                        sx={{
                            textAlign: 'center'
                        }}
                    >
                        <Component
                            {...rest}
                            row={row}
                        />
                    </Box>
                </TriggerableDialog>
            )}
        />
    );
};

export default DeleteColumnAction;
