import { memo, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import ProfileFinance from "ui/forms/Merchant/ProfileFinance";
import PayoutSettings from "ui/forms/Merchant/PayoutSettings";
import { TabLayout } from "ui/widgets/StatefulTabs";
import { useMerchantProfileContext } from "../MerchantProfileProvider";
import type { MerchantProfileRepository } from "../repository";
import useMerchantDetailsTabContext from "../../useMerchantDetailsTabContext";
import PayoutAccountSelector, { type PayoutAccountSelectorProps } from "./PayoutAccountSelector";
import ActionPanel from "ui/molecules/ActionPanel";
import { ConfirmableAction } from "ui/molecules/Action";

const Finance = () => {
    const repository = useMerchantDetailsTabContext<MerchantProfileRepository>();
    const repositoryRef = useRef(repository);
    repositoryRef.current = repository;

    const isLoading = repository.isLoading;
    const account = repository.account;

    const { selectFinance, selectPayoutSettings } = useMerchantProfileContext();

    const onPayoutAccountChange: PayoutAccountSelectorProps['onChange'] = account =>
        repository.selectPayoutAccount(account);

    const onAddAccount: PayoutAccountSelectorProps['onAdd'] = account =>
        repository.createPayoutAccount(account);

    useEffect(() => {
        const repository = repositoryRef.current;

        repository
            .fetchPayoutAccounts();

        repository
            .fetchMerchantSettings();
    }, []);

    const dialogContent = {
        dialogContent: [
            <Box>
                Are you sure you want to delete the {repository.accountName} account
                <br />
                associated with {repository.account?.accountHolderName}?
            </Box>,
        ],
        actionContent: 'Yes'
    };

    return (
        <>
            <TabLayout
                title='Finance'
                isLoading={isLoading}
                isEmpty={repository.isEmpty}
                isError={repository.isError}
                auxSlot={(
                    <PayoutAccountSelector
                        loading={isLoading}
                        value={account}
                        options={repository.accounts}
                        onChange={onPayoutAccountChange}
                        onAdd={onAddAccount}
                    />
                )}
            >
                <ProfileFinance
                    {...selectFinance()}
                />
                <ActionPanel
                    sx={{
                        borderTop: 'none',
                        justifyContent: 'flex-start'
                    }}
                    cancelActionSlot={(
                        <ConfirmableAction
                            ActionProps={{
                                children: 'Delete',
                                variant: 'contained',
                                color: 'error'
                            }}
                            DialogProps={{
                                content: [
                                    dialogContent,
                                    dialogContent
                                ],
                                onConfirm: () => selectFinance().onConfirmDeletePayoutAccount(account),
                                checkPredicate: () => true,
                                onCancel: () => { },
                                cancelActionContentSlot: 'No'
                            }}
                        />
                    )}
                    SaveActionProps={{
                        children: 'Edit',
                        variant: 'outlined'
                    }}
                    onSave={async () => selectFinance().toggleEditMode()}
                />
            </TabLayout>
            <Divider
                sx={{
                    my: 4
                }}
            />
            <TabLayout
                title='Payout settings'
            >
                <PayoutSettings
                    {...selectPayoutSettings()}
                />
            </TabLayout>
        </>
    );
};

export default memo(Finance);
