import { HttpVerb } from "consts/api";
import { route, when } from "util/support";
import { ApiStrategies } from "./helpers";
import {
    FormSerializers,
    type UrlString,
    type ExtendedRequestInfo
} from "./types";

export const withGetListToPost = (url: UrlString, {
    body,
    headers = {},
    getBody = ApiStrategies[FormSerializers.Json].getBody,
    getHeaders = ApiStrategies[FormSerializers.Json].getHeaders,
    ...restRequestInfo
}: ExtendedRequestInfo) => {
    const u = new URL(url);
    const entries = Array.from(u.searchParams.entries());

    return when(
        entries.length,
        () => {
            const {
                getBody,
                getHeaders
            } = ApiStrategies[FormSerializers.FormData];

            return {
                ...restRequestInfo,
                ...getBody(entries),
                headers: getHeaders(headers),
                method: HttpVerb.Post,
                url: `${u.origin}${route([u.pathname, 'l', 'i', 's', 't'])}`
            };
        },
        {
            ...restRequestInfo,
            ...getBody(body),
            headers: getHeaders(headers),
            url
        }
    );
};

const middleware = [
    withGetListToPost
];

export default middleware;
