import { memo } from "react";
import { useTypedSelector } from "hooks";
import { TableGridMode } from "consts/table";
import { filterTypes } from "consts/merchantPayouts";
import { selectFileTaxName } from "features/general/selectors";
import useMerchantPayoutsActions from "features/merchantPayouts/useActions";
import MerchantPayoutsTable from "../MerchantPayoutsTable";

const FileTaxCalculations = () => {
    const taxName = useTypedSelector(selectFileTaxName);
    const {
        getMerchantPayoutFileTaxCalculations
    } = useMerchantPayoutsActions();

    return (
        <MerchantPayoutsTable
            mode={TableGridMode.MerchantPayoutFileTaxCalculations}
            filterTypes={filterTypes}
            fetchTableData={getMerchantPayoutFileTaxCalculations}
            FiltersProps={{
                filtersModelOverrides: {
                    MerchantPayoutIncrementTaxName: taxName
                }
            }}
        />
    );
};

export default memo(FileTaxCalculations);
