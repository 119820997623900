import { memo } from "react";
import Resource from 'ui/layouts/Resource';
import { ImportWhitelist } from "ui/organizms/Consumers/ConsumerActions";
import WhitelistedConsumersTable from "./WhitelistedConsumersTable";

const WhitelistedConsumers = () => (
    <Resource
        breadcrumbsProps={{
            heading: 'Whitelist Import History',
            links: [{ name: '' }],
            action: (
                <ImportWhitelist
                    TriggerComponentProps={{
                        variant: 'outlined',
                        color: 'primary'
                    }}
                />
            )
        }}
    >
        <WhitelistedConsumersTable/>
    </Resource>
);

export default memo(WhitelistedConsumers);
