import { memo, useState, type PropsWithChildren } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { when } from "util/support";
import { NotificationSeverity } from "features/general/types";
import useActions from "features/general/useActions";

type Props = {
    readonly downloadTemplate: () => Promise<Response>;
};

const ImportWhitelistTemplate = ({ downloadTemplate, children }: PropsWithChildren<Props>) => {
    const [isLoading, setLoading] = useState(false);

    const { showNotication } = useActions();

    const displaySuccessfulNotification = () => showNotication({
        message: 'Template downloaded successfully',
        severity: NotificationSeverity.Success
    });

    const displayErrorNotification = () => showNotication({
        message: 'Failed to download template',
        severity: NotificationSeverity.Error
    });

    const handleDownloadTemplate = () => {
        setLoading(true);

        return downloadTemplate()
            .then(({ ok }) => when(
                ok,
                displaySuccessfulNotification,
                displayErrorNotification
            ))
            .catch(displayErrorNotification)
            .finally(() => setLoading(false));
    };

    return (
        <LoadingButton
            variant='outlined'
            color='info'
            loading={isLoading}
            onClick={handleDownloadTemplate}
        >
            {children}
        </LoadingButton>
    );
};

export default memo(ImportWhitelistTemplate);
