export type RequestBody =
  | Record<string, unknown>
  | Array<[string, unknown]>
  | FormData
  | null;

export interface ExtendedRequestInfo extends Omit<RequestInit, 'body'> {
  readonly body?: RequestBody;
  readonly baseUrl?: string;
  readonly abortController?: AbortController;
  readonly serializer?: FormSerializers;
  getBody?: (body?: RequestBody, csrfToken?: string) => object;
  getHeaders?: (headers?: object) => HeadersInit;
}

export type UrlString = string;

export type DownloadableRequestInfo = ExtendedRequestInfo & {
  readonly fileName: string;
};

export enum ApiState {
  Idle = 'idle',
  Pending = 'pending',
  Succeeded = 'succeeded',
  Failed = 'failed'
}

export enum FormSerializers {
  FormData,
  Json
}

export type Middleware = (
  url: UrlString,
  requestInfo: ExtendedRequestInfo
) => ExtendedRequestInfo & {
  readonly url: UrlString;
};
