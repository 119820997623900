import { memo, useState } from "react";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import WalletIcon from '@mui/icons-material/Wallet';
import { useTypedSelector } from "hooks";
import { useSimpleRequest } from "hooks/useRequest";
import { FormAdapter } from "hooks/useForm";
import { delay } from "util/support";
import { partialRefund } from "features/transactions/api";
import { selectIsTransactionDetailsLoading, selectTransaction } from "features/transactions/selectors";
import type { Transaction } from "features/transactions/types";
import useTransactionActions from "features/transactions/useActions";
import { useModal } from "ui/atoms/Modal";
import { BasicDialog } from "ui/molecules/Dialog";
import When from "ui/molecules/When";
import RefundAmountForm, { type RefundAmount } from "ui/forms/Transaction/RefundAmount";
import SubmitRefundAction from "./SubmitRefundAction";
import useRefundActions from "../useRefundTransactions";

type Props = Pick<Transaction, 'coreId' | 'MOR'>;

const PartialRefundAction = (props: Props) => {
    const transaction = useTypedSelector(selectTransaction(props, {}));

    const [isLoading, setLoading] = useState(false);

    const { getTransactionDetails } = useTransactionActions();
    const isTransactionDetailsLoading = useTypedSelector(selectIsTransactionDetailsLoading) || isLoading;

    const {
        coreId,
        MOR,
        refundableAmount: amount
    } = transaction;

    const request = useSimpleRequest();

    const { isPartialRefundAvailable } = useRefundActions(transaction);

    const {
        open,
        onClose,
        onOpen
    } = useModal();

    const handleRefund = async ({ amount }: Partial<RefundAmount>) => {
        setLoading(true);

        const response = await request(() => partialRefund({
            coreId,
            MOR,
            amount: Number(amount)
        }));

        await delay(2000);

        const [refresh] = getTransactionDetails(coreId);

        await refresh;

        setLoading(false);
        return FormAdapter.fromResponse(response);
    };

    return (
        <When
            if={isPartialRefundAvailable}
            then={() => (
                <>
                    <LoadingButton
                        onClick={onOpen}
                        variant='outlined'
                        size='small'
                        startIcon={<WalletIcon />}
                        loading={isTransactionDetailsLoading}
                    >
                        Refund
                    </LoadingButton>
                    <BasicDialog
                        isOpen={open}
                        isCanClose={false}
                        onClose={onClose}
                        DialogProps={{
                            sx: {
                                '& .MuiDialog-container .MuiPaper-root': {
                                    width: '100%',
                                    maxWidth: 445
                                }
                            }
                        }}
                    >
                        <RefundAmountForm
                            SubmitActionComponent={form => (
                                <SubmitRefundAction
                                    {...form}
                                    isSubmitting={isTransactionDetailsLoading}
                                    onCancel={onClose}
                                />
                            )}
                            onSaveOrCreate={handleRefund}
                            initialValues={{
                                amount
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'inherit',
                                    fontSize: 22,
                                    fontWeight: 600
                                }}
                                gutterBottom
                            >
                                Do you want to proceed with refund ?
                            </Typography>
                        </RefundAmountForm>
                    </BasicDialog>
                </>
            )}
        />
    );
};

export default memo(PartialRefundAction);
