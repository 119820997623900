import { memo, useCallback, useRef } from "react";
import {
    DateRangePicker,
    type DateRangePickerProps,
    type DateRangePickerToolbarProps
} from '@mui/x-date-pickers-pro-v.6/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro-v.6/SingleInputDateRangeField';
import type { PickersActionBarProps } from '@mui/x-date-pickers-v.6/PickersActionBar';
import type { Moment } from "moment";
import type { TextFieldProps } from "@mui/material/TextField";
import { useMui } from "hooks";
import type { AbstractActionableFilterProps } from "ui/widgets/Filters/types";
import { UiDateTimeFormatter } from "util/formaters";
import { PredefinedCalendarInterval } from "util/interval";
import { CustomActionBar, CustomToolbar } from "./slots";

export interface DateRangeFilterProps extends Omit<
    DateRangePickerProps<Moment>,
    'onChange'
>, AbstractActionableFilterProps {
    readonly startProps?: TextFieldProps;
    readonly endProps?: TextFieldProps;
}

const DateRangeFilter = ({
    onChange,
    id,
    startProps: fromDate = {},
    endProps: toDate = {},
    ...props
}: DateRangeFilterProps) => {
    const { withHandler } = useMui({
        timeout: 50
    });

    const actionBarPropsRef = useRef<Pick<PickersActionBarProps, 'onAccept'> | null>(null);

    const defaultInputProps = {
        sx: {
            width: 275,
            alignSelf: 'flex-start'
        },
        autoComplete: 'false'
    };

    const handleChange: DateRangePickerProps<Moment>['onChange'] = date =>
        onChange({
            type: id,
            payload: date
        });

    const ToolbarSlot = useCallback((props: DateRangePickerToolbarProps<Moment>) => (
        <CustomToolbar
            {...props}
            onCancel={() => actionBarPropsRef.current?.onAccept()}
        />
    ), []);

    return (
        <DateRangePicker
            disableFuture
            format={UiDateTimeFormatter.Ui}
            {...props}
            {...defaultInputProps}
            slots={{
                actionBar: CustomActionBar,
                field: SingleInputDateRangeField,
                toolbar: ToolbarSlot
            }}
            slotProps={{
                textField: {
                    size: 'small',
                    ...fromDate,
                    label: null,
                    sx: {
                        ':before': {
                            content: `"${fromDate.label}"`,
                            display: 'block',
                            fontSize: 13,
                            lineHeight: 1.84,
                            fontWeight: 500
                        },
                        ...defaultInputProps.sx,
                        ...fromDate.sx
                    },
                    InputProps: {
                        ...fromDate.InputProps,
                        sx: {
                            gap: 1,
                            ...fromDate.InputProps?.sx
                        }
                    }
                },
                shortcuts: {
                    items: PredefinedCalendarInterval.toArray(),
                },
                toolbar: {
                    hidden: false
                },
                actionBar: ({ onAccept }) => {
                    actionBarPropsRef.current = {
                        onAccept
                    };

                    return {
                        actions: ['clear']
                    };
                }
            }}
            onChange={handleChange}
            onOpen={withHandler()}
        />
    );
};

export default memo(DateRangeFilter);

/*export class DaterangeValueObject extends FilterValueObject<DateRange<Moment>, Moment | null> {
    // public toSerialized(environment?: Environment): string[] {
    //     const serializedValue = this.value
    //         .map(this.setTimeOfDate.bind(this))
    //         .map(date => date?.format(this.getEnvironmentFormatter(environment)) ?? null);

    //     if (serializedValue.every(date => !date)) {
    //         return [];
    //     }

    //     return serializedValue.map(String);
    // }

    public serialize(value: Moment | null, environment?: Environment | undefined): string {
        return String(value?.format(this.getEnvironmentFormatter(environment)) ?? null);
    }

    public toArray() {
        const dateRange = this.value
            .map(this.setTimeOfDate.bind(this))
            .map(date => date?.isValid() ? date : null);

        if (dateRange.every(date => !date)) {
            return [] as unknown as DateRange<Moment>;
        }

        return dateRange as DateRange<Moment>;
    }

    public isEmpty(_: DateRange<Moment> | Moment): boolean {
        return false;
    }

    private getEnvironmentFormatter(environment = Environment.Browser): string {
        return {
            [Environment.Browser]: DATE_FORMAT,
            [Environment.Server]: DATE_FORMAT
        }[environment];
    }

    private setTimeOfDate(date: Moment | null, modifier: number) {
        if (modifier <= 0) {
            return date?.startOf('day');
        }

        return date?.endOf('day');
    }
}

export const DaterangeValueObjectStrategy = {
    [ValueObjectStrategy.SearchQuery](values: Array<string>, meta?: DOMStringMap) {
        const initialValueStrategyName = (meta?.initialValueStrategy as ValueStrategy) ||
            ValueStrategy.Default;

        return new DaterangeValueObject(
            filterValueStrategy[FilterVariant.DateRange][initialValueStrategyName]!.getInitialValue(values)
        );
    },

    [ValueObjectStrategy.Change](values: DateRange<Moment>, meta?: DOMStringMap) {
        const changeValueStrategyName = (meta?.changeValueStrategy as ValueStrategy) ||
            ValueStrategy.Default;

        return new DaterangeValueObject(
            filterValueStrategy[FilterVariant.DateRange][changeValueStrategyName]!.handleChangeValue(values)
        );
    },

    getDefaultValue(meta?: DOMStringMap) {
        const defaultValueStrategyName = (meta?.defaultValueStrategy as ValueStrategy) ||
            ValueStrategy.Default;

        return filterValueStrategy[FilterVariant.DateRange][defaultValueStrategyName]!.getDefaultValue();
    }
};*/
