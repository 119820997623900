import { ApiRoutes } from "consts/enpoints/api";
import type { Setup } from 'features/general/types';
import { Setup as SetupType } from 'consts/general';
import { fetchApi } from "infrastructure/api";

export const getTicketingSystemSwitch = (queryString = '') =>
    fetchApi(`${ApiRoutes.GetTicketingSystemMainSwitch}${queryString}`, undefined, []);

export const getTicketingSystemZendeskTicketingSwitch = (queryString = '') =>
    fetchApi(`${ApiRoutes.GetTicketingSystemZendeskTicketingSwitch}${queryString}`, undefined, []);

export const updateTicketingSystemSwitch = (body: Pick<
    Setup<SetupType.TicketingSystem>,
    | 'MOR'
    | 'setupValue'
    | 'userId'
>) =>
    fetchApi(ApiRoutes.PutTicketingSystemMainSwitch, {
        method: 'PUT',
        body
    });

export const updateTicketingSystemZendeskTicketingSwitch = (body: Pick<
    Setup<SetupType.ZendeskTicketingSystem>,
    | 'MOR'
    | 'setupValue'
    | 'userId'
>) =>
    fetchApi(ApiRoutes.PutTicketingSystemZendeskTicketingSwitch, {
        method: 'PUT',
        body
    });
