import { memo } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import {
    FormValidator,
    getInitialValues,
    type ActionComponentProps
} from "ui/forms/User";
import { useResourceContext } from "ui/widgets/Resource";

const MerchantUserAction = ({ userFormikForm, userForm }: ActionComponentProps) => {
    const { onClose, getIsNew } = useResourceContext();

    const onSave = async () => {
        await userFormikForm.submitForm();
        onClose();
    };

    const onSaveAndCreateAnother = async () => {
        await userFormikForm.submitForm();

        userFormikForm.resetForm({
            errors: {},
            touched: {},
            values: getInitialValues(userForm.initialValues)
        });
    };

    const actionProps = {
        loading: (
            userFormikForm.isSubmitting ||
            userForm.isLoading ||
            userForm.isBootstrapDataLoading
        ),
        disabled: FormValidator.from({
            ...userFormikForm,
            bootstrapData: userForm.bootstrapData,
            isBootstrapDataLoading: userForm.isBootstrapDataLoading
        }).isInvalid
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2
            }}
        >
            <LoadingButton
                {...actionProps}
                variant='contained'
                onClick={onSave}
                disabled={!userFormikForm.dirty}
            >
                Save
            </LoadingButton>
            {
                getIsNew() && (
                    <LoadingButton
                {...actionProps}
                variant='contained'
                onClick={onSaveAndCreateAnother}
                disabled= {!userFormikForm.dirty}
            >
                Save And Create Another
            </LoadingButton>
                )
            }
        </Box>
    );
};

export default memo(MerchantUserAction);
