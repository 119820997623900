import { useState } from "react";
import { ConsumerActionType, useConsumerContext } from "../../ConsumerProvider";
import { BulkKYCStepperState as BulkKYCState, BulkKYCStepperStep } from "./BulkKYCStepper";
import useKYCServices from "features/kyc/useKYCServices";
import type { FileEntity } from "features/general/types";
import { useSimpleRequest } from "hooks/useRequest";
import { convertToFormData } from "util/transformers";
import { FORM_DATA_FILE_KEY } from "consts/forms";
import { postFile } from "features/general/api";
import { ManualBulkKYCsImportHistory } from "features/kyc/types";
import { createManualBulkKYCsImportHistory } from "features/kyc/api";
import { Filters } from "consts/transactions";
import { useTypedSelector } from "hooks";
import { selectDomains } from "features/general/selectors";

export default function useBulkKYCDialog() {
    const domains = useTypedSelector(selectDomains);

    const {
        servicesState,
        onKYCServiceChange,
        resetKYCServicesState
    } = useKYCServices();

    const request = useSimpleRequest();

    const [bulkKYCState, updateBulkKYCState] = useState(initializeBulkKYCState);

    const {
        consumersActionTypeProcessingState: [isSubmitting, toggleRequestProcessing],
        consumersState,
        resetConsumersState
    } = useConsumerContext();

    const onChange = <TStep extends BulkKYCStepperStep>(step: TStep) =>
        (value: BulkKYCState[TStep]) => {
            updateBulkKYCState(state => ({
                ...state,
                [step]: value
            }));
        };

    const validator = (step: BulkKYCStepperStep) => {
        const validationStrategies = new Map<BulkKYCStepperStep, () => boolean>()
            .set(BulkKYCStepperStep.UploadCSVFile, () =>
                Boolean(bulkKYCState[BulkKYCStepperStep.UploadCSVFile])
            )
            .set(BulkKYCStepperStep.SelectKYCTypesAndSubmit, () =>
                Boolean(bulkKYCState[step]) &&
                Object.values(servicesState)
                    .some(service => service.checked)
            );

        if (validationStrategies.has(step)) {
            return validationStrategies.get(step)!();
        }

        return true;
    };

    const onSkip = (step: BulkKYCStepperStep) => {
        const skipStrategies = new Map<BulkKYCStepperStep, () => void>()
            .set(BulkKYCStepperStep.UploadVerificationArchive, () => {
                onChange(BulkKYCStepperStep.UploadVerificationArchive)(null);
            });

        return skipStrategies.get(step)?.();
    };

    const handleDialogClose = () => {
        resetConsumersState();
        resetKYCServicesState();
        updateBulkKYCState(initializeBulkKYCState);
    };

    const onComplete = async () => {
        const merchantIdFallback = -1;
        let { id: merchantId } = bulkKYCState[BulkKYCStepperStep.SelectKYCTypesAndSubmit] ?? {
            id: merchantIdFallback
        };
        let fileEntities = Array.of<string>();

        toggleRequestProcessing(ConsumerActionType.BulkKYC);

        Promise.all(
            Object.values(bulkKYCState)
                .filter(file => {
                    const fileInstance: object = file ?? {};

                    return fileInstance instanceof File;
                })
                .map(file => request<FileEntity>(() => postFile(
                    convertToFormData({
                        [FORM_DATA_FILE_KEY]: file!
                    })
                )))
        )
            .then(([fileKeyResponse, kycFileKeyResponse]) => {
                fileEntities = [fileKeyResponse, kycFileKeyResponse]
                    .filter(Boolean)
                    .map(({ data }) => data.fileKey);
            })
            .catch(() => {
                merchantId = merchantIdFallback;
            })
            .finally(() => {
                const [fileKey, kycFileKey = null] = fileEntities;

                return request<ManualBulkKYCsImportHistory>(() =>
                    createManualBulkKYCsImportHistory({
                        fileKey,
                        kycFileKey,
                        merchantId,
                        kycTypeIds: Object.entries(servicesState)
                            .filter(([_, { checked }]) => checked)
                            .map(([kycType]) => kycType)
                            .join(),
                        MOR: domains[Filters.MOR]
                    }), { notifyOnSuccess: true });
            })
            .finally(() => {
                handleDialogClose();
                toggleRequestProcessing(ConsumerActionType.BulkKYC, false);
            });
    };

    return {
        open: consumersState?.type === ConsumerActionType.BulkKYC,
        bulkKYCState,
        servicesState,
        onKYCServiceChange,
        handleDialogClose,
        onChange,
        onComplete,
        validator,
        onSkip,
        isSubmitting
    };
};

function initializeBulkKYCState() {
    return {
        [BulkKYCStepperStep.UploadCSVFile]: undefined,
        [BulkKYCStepperStep.UploadVerificationArchive]: null,
        [BulkKYCStepperStep.SelectKYCTypesAndSubmit]: null
    };
}
