import type { GridRowParams } from "@mui/x-data-grid-premium";
import type { AggregateRoot } from "types";
import useTaxable from "./useTaxable";
import type { TaxableProps } from "./Props";
import DeleteColumnAction from "./DeleteColumnAction";
import FrontPanelWidget, { FrontPanelWidgetProvider } from "./FrontPanelWidget";
import MerchantPayoutsTable from "../MerchantPayoutsTable";

const Taxable = <TEntity extends AggregateRoot, TReturn = any, T = any>({
    deleteActionRequestHandler,
    importBulkRequestThunk,
    label,
    dialogProps,
    createTaxableEntitySlot,
    createTaxableActionLabel,
    downloadTemplateHandler,
    ...props
}: TaxableProps<TEntity, TReturn, T>) => {
    const { getInitialState } = useTaxable();

    return (
        <FrontPanelWidgetProvider
            createTaxableActionLabel={createTaxableActionLabel}
            downloadTemplateHandler={downloadTemplateHandler}
            importBulkRequestThunk={importBulkRequestThunk}
        >
            <MerchantPayoutsTable
                {...props}
                initialState={getInitialState()}
                columns={[
                    {
                        field: 'actions',
                        type: 'actions',
                        getActions: (params: GridRowParams) => [
                            <DeleteColumnAction
                                <TEntity>
                                {...params}
                                deleteActionRequestHandler={deleteActionRequestHandler}
                                label={label}
                                dialogProps={dialogProps}
                            />
                        ]
                    }
                ]}
                FiltersProps={{
                    FrontPanelWidget
                }}
            />
            {createTaxableEntitySlot}
        </FrontPanelWidgetProvider>
    );
};

export default Taxable;
