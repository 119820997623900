import type { ID } from "types";
import { ApiRoutes } from "consts/enpoints/api";
import { downloadFileApi, fetchApi } from "infrastructure/api";
import type { BlacklistedConsumer, Consumer, ConsumerKYCService } from "./types";
import { KYCProviders } from "consts/kyc";
import { Filters } from "consts/consumers";
import { BooleanSwitchValue } from "consts/general";

export const getConsumers = (queryString: string) =>
    fetchApi(`${ApiRoutes.GetConsumers}${queryString}`);

export const postConsumer = (
    body: Partial<Consumer>
) =>
    fetchApi(ApiRoutes.PostConsumer, {
        method: 'POST',
        body: {
            [Filters.active]: BooleanSwitchValue.Off,
            ...body
        }
    });

export const getConsumerByEmailOrId = ({ coreId, MOR }: Pick<Consumer, 'coreId' | 'MOR'>) =>
    fetchApi(ApiRoutes.GetConsumerByEmailOrId(coreId), {
        headers: {
            MOR
        }
    });

export const getConsumersBlacklist = (queryString: string) =>
    fetchApi(`${ApiRoutes.GetConsumersBlacklist}${queryString}`);

export const createConsumersBlacklist = (body: Partial<BlacklistedConsumer>) =>
    fetchApi(ApiRoutes.PostConsumersBlacklist, {
        method: 'POST',
        body
    });

export const deleteConsumersBlacklist = (hashEmailOrCoreId: ID) =>
    fetchApi(ApiRoutes.DeleteConsumersBlacklist(hashEmailOrCoreId), {
        method: 'DELETE'
    });

export const getConsumersWhitelist = (queryString: string) =>
    fetchApi(`${ApiRoutes.GetConsumersWhitelist}${queryString}`);

export const getConsumerEmails = (queryString: string) =>
    fetchApi(`${ApiRoutes.GetConsumerEmails}${queryString}`);

export const updateOrCreateConsumerEmails = ({ coreId, ...consumer }: Pick<
    Consumer,
    | 'coreId'
    | 'samePersonIdentifier'
> & Partial<
    Pick<
        Consumer,
        | 'email'
        | 'samePersonIdentifierSource'
    >
>) => fetchApi(ApiRoutes.PutConsumerEmails(coreId), {
    method: 'PUT',
    body: {
        ...consumer,
        consumerId: coreId
    }
});

export const updateConsumer = ({ coreId, ...body }: Partial<Consumer>) =>
    fetchApi(ApiRoutes.PutConsumer(`${coreId}`), {
        method: 'PUT',
        body
    });

export const getConsumerKYCSettings = (queryString: string) =>
    fetchApi(`${ApiRoutes.GetConsumersKYCSettings}${queryString}`);

export const triggerConsumerKYCSettings = (body: Partial<ConsumerKYCService>) =>
    fetchApi(ApiRoutes.PostConsumersKYCSettings, {
        method: 'POST',
        body: {
            ...body,
            provider: KYCProviders.ShuftiPro
        }
    });

export const getConsumersManualBulkKYCsImportHistoryPivot = (queryString: string) =>
    fetchApi(`${ApiRoutes.GetConsumersManualBulkKYCsImportHistoryPivot}${queryString}`);

export const downloadImportConsumerBlacklistTemplate = () =>
    downloadFileApi(ApiRoutes.GetImportConsumerBlacklist, {
        headers: {
            Accept: 'text/csv',
        },
        fileName: 'import_consumer_blacklist_template.csv'
    });

export const downloadTemplateEmailsWhitelistWithoutSdd = () =>
    downloadFileApi(ApiRoutes.GetTemplateEmailsWhitelistWithoutSdd, {
        headers: {
            Accept: 'text/csv',
        },
        fileName: 'emails_whitelist_template_without_sdd.csv'
    });

export const downloadTemplateEmailsWhitelist = () =>
    downloadFileApi(ApiRoutes.GetTemplateEmailsWhitelist, {
        headers: {
            Accept: 'text/csv',
        },
        fileName: 'emails_whitelist_template.csv'
    });
