import { PayloadAction } from "@reduxjs/toolkit";
import { ApiRoutes, ApiRouteTypes } from "consts/enpoints/api";
import { ApiState, ExtendedRequestInfo, downloadFileApi } from "infrastructure/api";
import { useCallback } from "react";
import { useAppDispatch } from "./store";

type DownloadTypes = 'csv';

type UseExportTableArgs = {
    readonly changeExportLoading: (payload: ApiState) => PayloadAction<ApiState>;
    readonly apiResourceType: ApiRouteTypes;
    readonly downloadType?: DownloadTypes
};

const useExportTable = ({
    changeExportLoading,
    apiResourceType,
    downloadType = 'csv'
}: UseExportTableArgs) => {
    const dispatch = useAppDispatch();

    return useCallback(async (
        queryParamString: string,
        getFileName = (
            resourceName: string,
            downloadType: DownloadTypes) => `${resourceName}_${Date.now()}.${downloadType}`,
        requestOptions: ExtendedRequestInfo = {
            headers: {
                Accept: `text/${downloadType}` // TODO: refactor into strategy
            }
        }
    ) => {
        dispatch(changeExportLoading(ApiState.Pending));

        const getExportRoute = () =>
            typeof ApiRoutes[apiResourceType] === 'function'
                ? (ApiRoutes[apiResourceType] as Function)()
                : ApiRoutes[apiResourceType]

        try {
            await downloadFileApi(`${getExportRoute()}${queryParamString}`, {
                ...requestOptions,
                fileName: getFileName(
                    apiResourceType.replace('Get', ''),
                    downloadType
                )
            });
            /*const response = await RefreshToken.refresh({ force: true })
                .finally()
                .then(() => fetchApi(`${getExportRoute()}${queryParamString}`, requestOptions));

            if (response.ok) {
                download({
                    fileName: getFileName(
                        apiResourceType.replace('Get', ''),
                        downloadType
                    ),// `transactions_${Date.now()}.csv`,
                    obj: (await response.blob())
                });
            }*/
        } finally {
            dispatch(changeExportLoading(ApiState.Idle));
        }
    }, [
        dispatch,
        changeExportLoading,
        apiResourceType,
        downloadType
    ]);
};

export default useExportTable;

