import {
    Fragment,
    memo,
    useCallback,
    useRef,
    useState,
    type FC,
    type MutableRefObject,
    type ComponentType
} from 'react';
import Box from '@mui/material/Box';
import { useFilterRequestMapper, type FiltersProps } from 'ui/widgets/Filters';
import { Table as TableGrid } from "ui/molecules/TableGrids";
import { ROWS_PER_PAGE_OPTIONS, TableGridMode } from 'consts/table';
import { FilterVariant } from 'consts/filters';
import useTable from '../hooks/useTable';
import type { RootState } from 'infrastructure/store';
import type { CoreBankingEntity, PaginateResourceResponse } from 'types';
import useTableColumns from '../hooks/useTableColumns';
import useSort from '../hooks/useSort';
import useColumnVisibility from '../hooks/useTableColumnsVisibility';
import usePaginate from '../hooks/usePaginate';
import useSums, { UseSumsArgs } from '../hooks/useSums';
import useTableInit from '../hooks/useTableInit';
import type {
    GridColumns,
    GridEventListener,
    GridRowIdGetter,
    GridPinnedColumns,
    GridRowParams,
    GridInputSelectionModel,
    GridSelectionModel,
    GridCallbackDetails,
    GridCellParams,
    GridExperimentalPremiumFeatures,
    GridValidRowModel,
    GridSlotsComponent
} from '@mui/x-data-grid-premium';
import type { CardProps } from '@mui/material/Card';
import type { Adapters, FiltersPublicApi } from '../types';
import type { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import type { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import CellProvider, { CellProviderProps } from '../contexts/CellProvider';
import TableFilters from './TableFilters';
import type { UseDefaultValues } from '../hooks/useDefaultValues';
import type { Theme } from '@mui/material';
import type { SystemStyleObject } from '@mui/system';
import type { LayoutProps } from 'ui/molecules/TableGrids/Table/Layout';
import useTableResetState from '../hooks/useTableResetState';
import useEvents from '../hooks/useEvents';

export type TableProps = {
    readonly mode: TableGridMode;
    readonly filterTypes: Map<FilterVariant, string[]>;
    readonly selectIsTableLoading: (state: RootState) => boolean;
    readonly selectTableDataSlice: (state: RootState) => PaginateResourceResponse<any>['data'];
    readonly selectIsTableUninitialized: (state: RootState) => boolean;
    readonly fetchTableData: (filterParams: string) => void;
    readonly downloadCsv: (filterParams: string) => void;
    readonly paginateOptions?: Pick<
        PaginateResourceResponse<null>['data'],
        | 'current_page'
        | 'per_page'
        | 'total'
    >;
    readonly DetailPanelContent?: FC<any>;
    readonly Toolbar?: FC<{
        readonly onExport: () => void;
    }>;
    readonly getRowId?: GridRowIdGetter<any>;
    readonly pinnedColumns?: GridPinnedColumns;
    readonly checkboxSelection?: boolean;
    readonly disableSelectionOnClick?: boolean;
    readonly CardProps?: CardProps;
    readonly onRowClick?: GridEventListener<"rowClick">;
    readonly getSummableColumns?: UseSumsArgs['getSummableColumns'];
    // Filters port
    readonly FiltersProps?: Pick<
        FiltersProps,
        | 'useVisibilityRules'
        | 'useFilterPropsOverride'
        | 'SummableWidget'
        | 'filtersModelOverrides'
        | 'FrontPanelWidget'
        | 'adapters'
        | 'sx'
        | 'isDefaultFiltersOpen'
    >;
    readonly initialState?: GridInitialStatePremium;
    readonly columns?: GridColumns;
    readonly isRowSelectable?: (params: GridRowParams) => boolean;
    readonly selectionModel?: GridInputSelectionModel;
    readonly onSelectionModelChange?: (selectionModel: GridSelectionModel, details: GridCallbackDetails<any>) => void;
    readonly isCellEditable?: (params: GridCellParams) => boolean;
    readonly experimentalFeatures?: Partial<GridExperimentalPremiumFeatures>;
    readonly processRowUpdate?: <T extends GridValidRowModel>(newRow: T, oldRow: T) => any;
    readonly onCellEditStop?: GridEventListener<"cellEditStop">;
    readonly onFilterInit?: FiltersProps['onChange'];
    readonly onFilterChange?: FiltersProps['onChange'];
    readonly onFiltersReset?: FiltersProps['onReset'];
    readonly filtersRef?: MutableRefObject<FiltersPublicApi<string> | null>;
    readonly requestQueryOverrideDecorator?: (
        aggregator: (
            fontendQueryParams: URLSearchParams,
            backendQueryParams: URLSearchParams
        ) => void
    ) => (
        fontendQueryParams: URLSearchParams,
        backendQueryParams: URLSearchParams
    ) => void;
    readonly CellProps?: CellProviderProps<object>;
    readonly pinnedColumnsSx?: Partial<{
        readonly right: SystemStyleObject<Theme>;
        readonly left: SystemStyleObject<Theme>;
    }>;
    readonly adapters?: Adapters;
    readonly Layout?: ComponentType<LayoutProps>;
    readonly LayoutProps?: LayoutProps;
    readonly tableApiRef?: MutableRefObject<GridApiPremium | undefined | null> | null;
    readonly components?: Partial<GridSlotsComponent>;
    readonly resetState?: () => void;
    readonly rowsPerPageOptions?: Array<number>;
} & UseDefaultValues;

const Table = ({
    mode,
    filterTypes,
    checkboxSelection,
    disableSelectionOnClick,
    selectIsTableLoading,
    selectTableDataSlice,
    selectIsTableUninitialized,
    fetchTableData,
    downloadCsv,
    paginateOptions,
    DetailPanelContent,
    Toolbar,
    getRowId,
    CardProps,
    onRowClick,
    initialState,
    pinnedColumns,
    isRowSelectable,
    selectionModel,
    onSelectionModelChange,
    isCellEditable,
    experimentalFeatures,
    processRowUpdate,
    onCellEditStop,
    filtersRef,
    requestQueryOverrideDecorator,
    getSummableColumns,
    onFilterInit,
    onFilterChange,
    onFiltersReset,
    resetState,
    defaultValues,
    pinnedColumnsSx,
    adapters,
    Layout,
    components,
    tableApiRef: tableApiRefProp,
    rowsPerPageOptions = ROWS_PER_PAGE_OPTIONS,
    columns: columnOverrides = [],
    FiltersProps = {},
    CellProps = {},
    LayoutProps = {}
}: TableProps) => {
    const [filtersApi, setFiltersApi] = useState<FiltersPublicApi<string> | null>();
    const filtersApiRef = useRef(filtersApi);
    filtersApiRef.current = filtersApi;

    const tableApiRef = useRef<GridApiPremium>();
    if (tableApiRefProp && tableApiRef.current) {
        tableApiRefProp.current = tableApiRef.current;
    }

    // Core table methods
    const {
        fetchData,
        sortable,
        data,
        currentPage,
        perPage,
        total,
        isLoading
    } = useTable({
        rowsPerPageOptions,
        selectIsTableLoading,
        selectTableDataSlice,
        fetchTableData,
        filterTypes,
        paginateOptions,
        requestQueryOverrideDecorator,
        defaultValues,
        adapters
    });

    // Table colums
    const {
        columns,
        getColumns,
        onColumnOrderChange
    } = useTableColumns({
        mode,
        sortable,
        columnOverrides,
        tableApiRef,
        adapters
    });

    // const resetUrlSearchParamsBeforeApply = (urlSearchParams: URLSearchParams) => {
    //     urlSearchParams.delete(Paginate.page);
    // };

    // Filters
    // const {
    //     filters,
    //     getFiltersRegistryUrlSearchParams,
    //     onChange,
    //     onReset,
    //     onApply
    // } = useFilters({
    //     mode,
    //     filterTypes,
    //     fetchData,
    //     // onBeforeApply: resetUrlSearchParamsBeforeApply,
    //     onBeforeFetch: useSums({
    //         selectTableDataSlice,
    //         getSummableColumns
    //     }),
    //     onFilterInit
    // });

    // Expose filters public api
    // useImperativeHandle(filtersRef, () => filtersApiRef.current!, []);
    // useEffect(() => {
    //     if (filtersRef && filtersApiRef) {
    //         filtersRef.current = filtersApiRef.current;
    //     }
    // });
    // useEffect(() => {
    //     if (filtersRef) {
    //         filtersRef.current = {
    //             filters,
    //             getFiltersRegistryUrlSearchParams,
    //             onChange,
    //             onReset,
    //             onApply
    //         };
    //     }
    // });

    // const handleFilterChange: FiltersProps['onChange'] = (...args) => {
    //     onFilterChange?.(...args);
    //     onChange(...args);
    // };

    // const handleFiltersReset: FiltersProps['onReset'] = (...args) => {
    //     onFiltersReset?.(...args);
    //     onReset(...args);
    // };

    // Sorting functionality
    const {
        onSortModelChange,
        sortModel,
        getSortUrlSearchParams
    } = useSort({ fetchData, adapters });

    // Columns visibility
    const {
        columnVisibilityModel,
        onColumnVisibilityModelChange,
        getColumnVisibilityUrlSearchParams
    } = useColumnVisibility({ getColumns, mode, adapters });

    // Pagination
    const onPageChange = usePaginate({ fetchData, adapters });

    // Normalize search query params to BE understandable type
    const getParamsForRequest = useFilterRequestMapper({
        filterTypes,
        requestQueryOverrideDecorator
    });

    const getRowIdFallback: GridRowIdGetter<CoreBankingEntity> = useCallback(({ coreId }: CoreBankingEntity) =>
        coreId, []);

    const onExport = () => {
        const urlSearchParams =
            getColumnVisibilityUrlSearchParams(
                getSortUrlSearchParams(
                    filtersApiRef.current!.getFiltersRegistryUrlSearchParams()
                ),
                ({ disableExport = false }) => !disableExport
            );

        downloadCsv(
            getParamsForRequest(
                urlSearchParams
            )
        );
    };

    useTableInit({
        selectIsTableUninitialized,
        onInit: filtersApiRef.current?.onApply
    });

    useTableResetState(resetState);

    useEvents(filtersApiRef.current);

    const ToolbarComponent = () => {
        const Component = Toolbar ?? components?.Toolbar ?? Fragment;

        return (
            <Component onExport={onExport} />
        );
    };

    return (
        <CellProvider
            {...CellProps}
        >
            <Box
                sx={{
                    '& .MuiDataGrid-pinnedColumnHeaders--right, & .MuiDataGrid-pinnedColumns--right': {
                        ...pinnedColumnsSx?.right
                    }
                }}
            >
                <TableFilters
                    {...FiltersProps}
                    ref={setFiltersApi}
                    filtersRef={filtersRef}
                    mode={mode}
                    filterTypes={filterTypes}
                    onBeforeFetch={useSums({
                        selectTableDataSlice,
                        getSummableColumns
                    })}
                    fetchData={fetchData}
                    onFilterInit={onFilterInit}
                    onFilterChange={onFilterChange}
                    onFiltersReset={onFiltersReset}
                    // filters={filters}
                    // onApply={onApply}
                    // onChange={handleFilterChange}
                    // onReset={handleFiltersReset}
                />
                <TableGrid
                    tableApiRef={tableApiRef}
                    columns={columns}
                    data={data}
                    currentPage={currentPage}
                    perPage={perPage}
                    rowsPerPageOptions={rowsPerPageOptions}
                    total={total}
                    isLoading={isLoading}
                    DetailPanelContent={DetailPanelContent}
                    components={{
                        ...components,
                        Toolbar: ToolbarComponent
                    }}
                    sortModel={sortModel}
                    onPageChange={onPageChange}
                    onSortModelChange={onSortModelChange}
                    onColumnOrderChange={onColumnOrderChange}
                    onColumnVisibilityModelChange={onColumnVisibilityModelChange}
                    columnVisibilityModel={columnVisibilityModel}
                    getRowId={getRowId || getRowIdFallback}
                    CardProps={CardProps}
                    onRowClick={onRowClick}
                    initialState={initialState}
                    checkboxSelection={checkboxSelection}
                    disableSelectionOnClick={disableSelectionOnClick}
                    pinnedColumns={pinnedColumns}
                    isRowSelectable={isRowSelectable}
                    selectionModel={selectionModel}
                    onSelectionModelChange={onSelectionModelChange}
                    isCellEditable={isCellEditable}
                    experimentalFeatures={experimentalFeatures}
                    processRowUpdate={processRowUpdate}
                    onCellEditStop={onCellEditStop}
                    Layout={Layout}
                    LayoutProps={LayoutProps}
                />
            </Box>
        </CellProvider>
    );
};

export default memo(Table);
