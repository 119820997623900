import type { ID } from "types";
import { ApiRoutes } from "consts/enpoints/api";
import { fetchApi } from "infrastructure/api";
import type { Transaction, TransactionHistoryRequestParams } from "./types";
import { RequestQueryMapper } from "util/request-query-mapper";
import { Aggregates } from "consts/request-query";
import { Filters } from "consts/transactions";
import { BooleanSwitchValue } from "consts/general";

export const getTransactionFilters = () =>
    fetchApi(ApiRoutes.GetTransactionFilters)

export const getBankSdk = (queryString: string) =>
    fetchApi(ApiRoutes.GetBankSdk(queryString));

export const getTransactionById = (transactionId: ID) =>
    fetchApi(ApiRoutes.GetTransactionById(transactionId));

export const getTransactionHistory = ({
    transactionId,
    transactionsGuid
}: TransactionHistoryRequestParams) =>
    fetchApi(
        ApiRoutes.GetTransactionHistory(
            transactionId,
            transactionsGuid
        )
    );

export const clearExport = () =>
    fetchApi(ApiRoutes.GetClearExport);

export const downloadCsv = () =>
    fetchApi(ApiRoutes.GetDownloadCSV);

export const getInNotifications = (queryString: string) =>
    fetchApi(`${ApiRoutes.GetInNotifications}${queryString}`);

export const getOutNotifications = (queryString: string) =>
    fetchApi(`${ApiRoutes.GetOutNotifications}${queryString}`);

export const getTransactions = (queryString: string) =>
    fetchApi(`${ApiRoutes.GetTransactions()}${queryString}`);

export const getEsTransactions = (queryString: string) =>
    fetchApi(`${ApiRoutes.GetEsTransactions}${
            RequestQueryMapper
                .from(queryString)
                .aggregates(Aggregates.Submins, '')
                .aggregates(Aggregates.Submaxs, '')
                .containsIn(Filters.parentId, `${BooleanSwitchValue.Off}`)
    }`);

export const getTransactionDetails = ({ coreId, MOR }: Pick<Transaction, 'coreId' | 'MOR'>) =>
    fetchApi(ApiRoutes.GetTransactions(coreId), {
        headers: {
            MOR
        }
    });

export const getTransactionOrigins = (queryString: string) =>
    fetchApi(`${ApiRoutes.GetTransactionOrigins}${queryString}`);

export const sendNotification = ({ coreId, MOR }: Pick<Transaction, 'coreId' | 'MOR'>) =>
    processTransactionAction(coreId, {
        Action: 'sendNotification',
        MOR
    });

export const checkStatus = ({ coreId, MOR }: Pick<Transaction, 'coreId' | 'MOR'>) =>
    processTransactionAction(coreId, {
        Action: 'checkStatus',
        MOR
    });

export const fakeRedirect = ({ coreId, MOR }: Pick<Transaction, 'coreId' | 'MOR'>) =>
    processTransactionAction(coreId, {
        Action: 'fakeRedirect',
        MOR
    });

export const partialRefund = ({
    coreId,
    MOR,
    ...body
}: Pick<Transaction, 'coreId' | 'MOR' | 'amount'>) =>
    fetchApi(ApiRoutes.GetTransactions(coreId), {
        method: 'PATCH',
        headers: {
            Action: 'refund',
            MOR
        },
        body
    });

export const refund = ({ coreId, MOR }: Pick<Transaction, 'coreId' | 'MOR'>) =>
    processTransactionAction(coreId, {
        Action: 'refund',
        MOR
    });

export const settlePingOrder = ({ coreId, MOR }: Pick<Transaction, 'coreId' | 'MOR'>) =>
    fetchApi(ApiRoutes.PingTransactions(coreId), {
        method: 'PATCH',
        headers: {
            Action: 'settleOrder',
            MOR
        }
    });

export const closePingOrder = ({ coreId, MOR }: Pick<Transaction, 'coreId' | 'MOR'>) =>
    fetchApi(ApiRoutes.PingTransactions(coreId), {
        method: 'PUT',
        headers: {
            MOR
        },
        body: {
            is_order_closed: 1
        }
    });

function processTransactionAction(coreId: ID, headers: Record<string, string>) {
    return fetchApi(ApiRoutes.GetTransactions(coreId), {
        method: 'PATCH',
        headers
    });
}

// export const sendExport = (queryString: string) =>
//     fetchApi(`${ApiRoutes.GetTransactions}${queryString}`, {
//         headers: {
//             Accept: 'text/csv'
//         }
//     });
