import { memo } from "react";
import { TableGridMode } from "consts/table";
import { filterTypes } from "consts/merchantPayouts";
import useMerchantPayoutsActions from "features/merchantPayouts/useActions";
import { downloadIncremetTaxesCSVTemplate } from "features/merchantPayouts/api";
import { postMerchantPayoutIncrementTaxesBulkThunk } from "features/merchantPayouts/thunks";
import Taxable from "../Taxable";
import AddIncrementTaxesAction from "./AddIncrementTaxesAction";
import DeleteIncrementExchangeRatesDialogBody from "./DeleteIncrementTaxesDialogBody";

const IncrementTax = () => {
    const {
        getMerchantPayoutIncrementTaxes,
        deleteMerchantPayoutIncrementTaxes
    } = useMerchantPayoutsActions();

    return (
        <Taxable
            mode={TableGridMode.MerchantPayoutIncrementTaxes}
            filterTypes={filterTypes}
            fetchTableData={getMerchantPayoutIncrementTaxes}
            downloadTemplateHandler={downloadIncremetTaxesCSVTemplate}
            deleteActionRequestHandler={deleteMerchantPayoutIncrementTaxes}
            importBulkRequestThunk={postMerchantPayoutIncrementTaxesBulkThunk}
            createTaxableActionLabel="Add new tax"
            createTaxableEntitySlot={(
                <AddIncrementTaxesAction />
            )}
            label="Delete tax"
            dialogProps={{
                children: DeleteIncrementExchangeRatesDialogBody
            }}
        />
    );
};

export default memo(IncrementTax);