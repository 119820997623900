import { memo } from "react";
import type { GridCellParams } from "@mui/x-data-grid-premium";
import { useTypedSelector } from "hooks";
import { TableGridMode } from "consts/table";
import { filterTypes, MerchantPayoutsTabs } from "consts/merchantPayouts";
import { selectMerchantPayoutIncrementsVia } from "features/general/selectors";
import useMerchantPayoutsActions from "features/merchantPayouts/useActions";
import Filable from "../Filable";
import Actions from "./Actions";

const SummaryFiles = () => {
    const merchantPayoutIncrementsVia = useTypedSelector(selectMerchantPayoutIncrementsVia);
    const {
        getMerchantPayoutSummaryFiles,
        postMerchantPayoutSummaryFiles,
        mergeMerchantPayoutSummaryFiles
    } = useMerchantPayoutsActions();

    return (
        <Filable
            mode={TableGridMode.MerchantPayoutsSummaryFiles}
            filterTypes={filterTypes}
            fetchTableData={getMerchantPayoutSummaryFiles}
            FiltersProps={{
                filtersModelOverrides: {
                    Via: merchantPayoutIncrementsVia
                }
            }}
            formId={MerchantPayoutsTabs.SummaryFiles}
            dialogTitleSlot='Create Payout Summary File'
            createNewFileRequestHandler={postMerchantPayoutSummaryFiles}
            mergeFilesRequestHandler={mergeMerchantPayoutSummaryFiles}
            columns={[
                {
                    field: 'actions',
                    renderCell: (params: GridCellParams) => (
                        <Actions {...params} />
                    )
                }
            ]}
        />
    );
};

export default memo(SummaryFiles);
