import type { GridColDef, GridColumns } from '@mui/x-data-grid';
import transactions from 'config/tables/transactions.json';
import transactionList from 'config/tables/transactionList.json';
import merchants from 'config/tables/merchants.json';
import users from 'config/tables/users.json';
import merchantPayoutSummaryFiles from 'config/tables/merchantPayouts/summaryFiles.json';
import merchantPayoutFiles from 'config/tables/merchantPayouts/files.json';
import merchantPayoutCalculations from 'config/tables/merchantPayouts/calculations.json';
import merchantPayoutFileTaxCalculations from 'config/tables/merchantPayouts/fileTaxCalculations.json';
import transactionsMerchantPayoutIncrementsPivot from 'config/tables/transactionsMerchantPayoutIncrementsPivot.json';
import merchantPayoutIncrementTaxes from 'config/tables/merchantPayouts/incrementTaxes.json';
import merchantPayoutIncrements from 'config/tables/merchantPayouts/increments.json';
import merchantPayoutIncrementExchangeRates from 'config/tables/merchantPayouts/incrementExchangeRates.json';
import merchantPayoutSettlementsImportHistory from 'config/tables/merchantPayouts/settlementsImportHistory.json';
import allConsumers from 'config/tables/consumers/all.json';
import blacklistedConsumers from 'config/tables/consumers/blacklisted.json';
import whitelistedConsumers from 'config/tables/consumers/whiteListed.json';
import activeConsumers from 'config/tables/consumers/active.json';
import manualBulkKYCsImportHistory from 'config/tables/manualBulkKYCsImportHistory.json';
import merchantUsers from 'config/tables/merchantUsers.json';
import ledgers from 'config/tables/ledgers.json';

import { TableGridMode } from 'consts/table';
import { generalTableParser } from 'util/parsers';
import InvalidTableGridModeException from '../exceptions/InvalidTableGridModeException';
import { convertArrayToMap } from 'util/transformers';

const configRegistry = new Map<TableGridMode, GridColDef[]>([
    [TableGridMode.Transaction, typed(transactions)],
    [TableGridMode.Merchant, typed(merchants)],
    [TableGridMode.User, typed(users)],
    [TableGridMode.MerchantPayoutsSummaryFiles, typed(merchantPayoutSummaryFiles)],
    [TableGridMode.MerchantPayoutsFiles, typed(merchantPayoutFiles)],
    [TableGridMode.MerchantPayoutCalculations, typed(merchantPayoutCalculations)],
    [TableGridMode.MerchantPayoutFileTaxCalculations, typed(merchantPayoutFileTaxCalculations)],
    [TableGridMode.TransactionsMerchantPayoutIncrementsPivot, typed(transactionsMerchantPayoutIncrementsPivot)],
    [TableGridMode.MerchantPayoutIncrementTaxes, typed(merchantPayoutIncrementTaxes)],
    [TableGridMode.MerchantPayoutIncrements, typed(merchantPayoutIncrements)],
    [TableGridMode.MerchantPayoutIncrementExchangeRates, typed(merchantPayoutIncrementExchangeRates)],
    [TableGridMode.MerchantPayoutsSettlementsImportHistory, typed(merchantPayoutSettlementsImportHistory)],
    [TableGridMode.Consumer, typed(allConsumers)],
    [TableGridMode.BlacklistedConsumer, typed(blacklistedConsumers)],
    [TableGridMode.WhitelistedConsumer, typed(whitelistedConsumers)],
    [TableGridMode.ActiveConsumer, typed(activeConsumers)],
    [TableGridMode.ManualBulkKYCsImportHistory, typed(manualBulkKYCsImportHistory)],
    [TableGridMode.TransactionList, typed(transactionList)],
    [TableGridMode.MerchantUser, typed(merchantUsers)],
    [TableGridMode.MerchantLedger, typed(ledgers)]
]);

export default function useTableConfig(mode: TableGridMode, overrideColumns: GridColumns) {
    if (!configRegistry.has(mode)) {
        throw new InvalidTableGridModeException(
            `Unknown table grid mode`,
            {}
        );
    }

    const columns = configRegistry.get(mode)!;

    const getGridColumnsWithOverrides = (columns: GridColumns) => {
        const indexColumn = 'field';

        const configColumnsMap = convertArrayToMap(columns, indexColumn);
        const overrideColumnsMap = convertArrayToMap(overrideColumns, indexColumn);

        // Override existing config
        columns.forEach(currentColumn => {
            const { field } = currentColumn;

            if (overrideColumnsMap.has(field)) {
                configColumnsMap.set(field, overrideColumnsMap.get(field));
                overrideColumnsMap.delete(field);
            }
        }, new Map<string, GridColDef>());

        // Add new columns
        return [
            ...Array.from(configColumnsMap.values()),
            ...Array.from(overrideColumnsMap.values())
        ];
    };

    const computedGridColumns = getGridColumnsWithOverrides(columns);

    return [
        generalTableParser(computedGridColumns),
        computedGridColumns
    ];
};


function typed<T = Array<GridColDef>>(columns: any) {
    return columns as T;
}
