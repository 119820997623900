import { memo } from "react";
import { TableGridMode } from "consts/table";
import { filterTypes } from "consts/merchantPayouts";
import useMerchantPayoutsActions from "features/merchantPayouts/useActions";
import { postMerchantPayoutIncrementExchangeRatesBulkThunk } from "features/merchantPayouts/thunks";
import { downloadIncremetExchangeRatesCSVTemplate } from "features/merchantPayouts/api";
import Taxable from "../Taxable";
import AddIncrementExchangeRateAction from "./AddIncrementExchangeRateAction";
import DeleteIncrementExchangeRatesDialogBody from "./DeleteIncrementExchangeRatesDialogBody";

const IncrementExchangeRates = () => {
    const {
        getMerchantPayoutIncrementExchangeRates,
        deleteMerchantPayoutIncrementExchangeRates
    } = useMerchantPayoutsActions();

    return (
        <Taxable
            mode={TableGridMode.MerchantPayoutIncrementExchangeRates}
            filterTypes={filterTypes}
            fetchTableData={getMerchantPayoutIncrementExchangeRates}
            downloadTemplateHandler={downloadIncremetExchangeRatesCSVTemplate}
            deleteActionRequestHandler={deleteMerchantPayoutIncrementExchangeRates}
            importBulkRequestThunk={postMerchantPayoutIncrementExchangeRatesBulkThunk}
            createTaxableActionLabel="Add new rate"
            createTaxableEntitySlot={(
                <AddIncrementExchangeRateAction />
            )}
            label="Delete exchange rate"
            dialogProps={{
                children: DeleteIncrementExchangeRatesDialogBody
            }}
        />
    );
};

export default memo(IncrementExchangeRates);
