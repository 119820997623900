import BusinessLogicException from "exceptions/BusinessLogicException";
import type { ActionHookArg } from "../Props";
import { generateFileName } from "util/support";
import { useSimpleRequest } from "hooks/useRequest";
import useActionHandler from "./useActionHandler";
import type { AbstractFileEntity } from "features/merchantPayouts/types";
import useActions from "features/merchantPayouts/useActions";

const defaultRequestHandler = async (
    fileEntity: Pick<AbstractFileEntity, 'coreId' | 'MOR'>,
    fileName: string
) => new Response();

export default function useGenerateSettlementXLS<T extends AbstractFileEntity>({
    row,
    handler = defaultRequestHandler
}: ActionHookArg<
    T,
    typeof defaultRequestHandler
>) {
    const { updateMerchantPayoutEntityById } = useActions();
    const actionHandler = useActionHandler(row);

    const request = useSimpleRequest();

    return actionHandler(async () => {
        if (!row.coreId) {
            throw new BusinessLogicException("Invalid fileKey!", {});
        }

        await request(() => handler(
            row,
            generateFileName({
                prefix: 'Generated Settlement',
                fileKey: row.fileKey,
                extension: 'xls'
            })
        ), {
            notifyOnSuccess: true,
            onSuccess: () => updateMerchantPayoutEntityById({
                ...row,
                fileKey: 'Generating...'
            }),
            onError: response => {
                throw new BusinessLogicException("Something went wrong while generating .XLS file", { response })
            }
        });
    });
};
