import { memo } from 'react';
import type {
    GridCellEditStopParams,
    GridEventListener,
    GridRenderEditCellParams,
    MuiEvent
} from '@mui/x-data-grid-premium';
import { useTypedSelector } from 'hooks';
import { TableGridMode } from 'consts/table';
import { Filters, filterTypes } from 'consts/merchantPayouts';
import { selectMerchantPayoutIncrementsVia } from 'features/general/selectors';
import useMerchantPayoutsActions from 'features/merchantPayouts/useActions';
import { useProcessRowUpdate } from 'ui/molecules/TableGrids';
import UpdateIncrementsDialog from './UpdateIncrementsDialog';
import EditCurrencyExchangeRateCell from './EditCurrencyExchangeRateCell';
import MerchantPayoutsTable from '../MerchantPayoutsTable';
import useIncrements from './useIncrements';

const Increments = () => {
    const merchantPayoutIncrementsVia = useTypedSelector(selectMerchantPayoutIncrementsVia);
    const {
        getMerchantPayoutIncrements
    } = useMerchantPayoutsActions();
    const {
        isCellEditable,
        initialState,
        updateRequestHandler,
        isRowModelEqual,
        onFilterChange,
        onFiltersReset,
        FiltersProps
    } = useIncrements();

    const updateRowProps = useProcessRowUpdate({
        isRowModelEqual,
        requestHandler: updateRequestHandler
    });

    const onCellEditStop: GridEventListener<"cellEditStop"> = (_: GridCellEditStopParams, event: MuiEvent) => {
        event.defaultMuiPrevented = true;
    };

    return (
        <>
            <UpdateIncrementsDialog
                {...updateRowProps}
            />
            <MerchantPayoutsTable
                mode={TableGridMode.MerchantPayoutIncrements}
                filterTypes={filterTypes}
                fetchTableData={getMerchantPayoutIncrements}
                onFilterInit={onFilterChange}
                onFilterChange={onFilterChange}
                onFiltersReset={onFiltersReset}
                FiltersProps={{
                    ...FiltersProps,
                    filtersModelOverrides: {
                        Via: merchantPayoutIncrementsVia,
                        ...FiltersProps?.filtersModelOverrides
                    }
                }}
                initialState={initialState}
                isCellEditable={isCellEditable}
                processRowUpdate={updateRowProps.processRowUpdate}
                onCellEditStop={onCellEditStop}
                columns={[
                    {
                        field: Filters.currencyExchangeRate,
                        headerName: "Currency Exchange Rate",
                        minWidth: 150,
                        resizable: false,
                        // disableColumnMenu: true,
                        editable: true,
                        renderEditCell: (params: GridRenderEditCellParams<number>) => (
                            <EditCurrencyExchangeRateCell {...params} />
                        )
                    },
                ]}
            />
        </>
    );
};

export default memo(Increments);
