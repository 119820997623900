import type { ID } from "types";
import { blobDownload } from "util/download";
import { ApiStrategies, applyMiddleware, RefreshToken } from "./helpers";
import {
    FormSerializers,
    type Middleware,
    type DownloadableRequestInfo,
    type ExtendedRequestInfo,
    type UrlString
} from "./types";
import mw from "./middleware";

const fetchApi = async (
    url: UrlString,
    requestInfo?: ExtendedRequestInfo,
    middleware: Array<Middleware> = mw
) => {
    await RefreshToken.refresh()
        .catch(() => window.location.reload());

    const {
        baseUrl = process.env.REACT_APP_BACKEND_ORIGIN_URL,
        abortController = new AbortController(),
        signal = abortController.signal,
        serializer = FormSerializers.Json,
        getBody = ApiStrategies[serializer].getBody,
        getHeaders = ApiStrategies[serializer].getHeaders,
        ...initRequestInfo
    } = requestInfo ?? {} as ExtendedRequestInfo;

    const {
        url: href,
        ...restRequestInfo
    } = fetchApi.withMiddleware(middleware)(`${baseUrl}${url}`, {
        ...initRequestInfo,
        signal,
        baseUrl,
        abortController,
        serializer,
        getBody,
        getHeaders
    });

    return fetch(href, restRequestInfo as RequestInit);
};

fetchApi.withMiddleware = applyMiddleware;

type CreateOrUpdateResourceArgs<T> = {
    readonly body: Partial<T>;
    readonly getRoute: (id?: ID) => string;
    readonly getId?: (body: Partial<T>) => ID | undefined;
};

export function createOrUpdateResource<T>({
    body,
    getRoute,
    getId
}: CreateOrUpdateResourceArgs<T>) {
    const resourceId = getId?.(body);

    return fetchApi(getRoute(resourceId), {
        method: resourceId ? 'PUT' : 'POST',
        body,
        serializer: FormSerializers.Json
    });
};

export const downloadFileApi = async (
    url: string,
    { fileName, ...extendedRequestSettings }: DownloadableRequestInfo
) => {
    console.log('Step 1. Enter downloadFileApi function => ', { url, fileName, ...extendedRequestSettings });
    const response = await RefreshToken.refresh({ force: true })
        .catch(() => window.location.reload())
        .finally()
        .then(() => fetchApi(url, extendedRequestSettings));
    console.log('Step 2. Refresh token => ');

    if (response.ok) {
        console.log('Step 3. Successfully refreshed token, start retreving Blob from response!!!!! => ');
        // 1. Go and serach for FE file download library
        // 2. BE need to upload temp file to S3 and return URL

        /// Renalda problem in response.blob() it stuck forever
        console.log('Step 4. Retreving Blob from response!!!!! => ');
        const obj = await response.blob();

        console.log('Step 5. Blob successfully retrieved, start downloading => ', obj);
        blobDownload({
            fileName,
            obj
        });
    }

    return response;
};

export default fetchApi;
