import { useEffect } from "react";

const handlers = {
    rm: () => {
        for (const element of Array.from(
            document.querySelectorAll('.DateRangePicker:not(.MuiDateRangePickerInput-root)>div:first-child,.MuiDataGrid-main>div:nth-last-child(2),.MuiDateRangeCalendar-root>div:first-child')
        )) {
            element.innerHTML = '';
        }
    }
};

type UseMuiArg = {
    readonly deps?: Array<unknown>;
    readonly timeout?: number;
};

export default function useMui({ deps = [], timeout = 0 }: UseMuiArg = {}) {
    const dependencies = JSON.stringify(deps);

    useEffect(() => {
        const timeoutId = setTimeout(handlers.rm, timeout);

        return () => clearTimeout(timeoutId);
    }, [dependencies, timeout]);

    const withHandler = (fn?: Function) => (...args: Array<unknown>) => {
        setTimeout(handlers.rm, timeout);

        return fn?.(...args);
    };

    return {
        withHandler
    };
};
