import Autocomplete from 'ui/atoms/Autocomplete';
import LoadingButton from '@mui/lab/LoadingButton';
import type { Option } from "types";
import { memo, SyntheticEvent } from "react";
import useUserPivots from "./useUserPivots";
import {
    isRoleEnabled,
    isMerchantEnabled,
    isMorEnabled,
    isMerchantRequired,
    isDomainRequired,
    getDomainsOptions,
    isMerchantMultiple
} from './helpers';
import { FormField, type UserFormProps } from "./types";
import { getOptionById, getOptionName } from "util/option";
import { FormValidator, MerchantValidator } from "./validators";
import { useForm } from "./useForm";
import UserForm from "./UserForm";
import useFormRef from './useFormRef';

const User = (props: UserFormProps) => {
    const {
        initialValues,
        isLoading,
        actionLabel,
        bootstrapData,
        isBootstrapDataLoading,
        user,
        TextFieldProps,
        AutocompleteProps,
        getActionButtonProps,
        formRef,
        ActionComponent
    } = props;

    const formik = useForm(props);

    const getValue = (formikValue?: Option[], isMultiple?: boolean) =>
        isMultiple
            ? [formikValue]
                .flat()
                .filter(Boolean)
            : (Array.isArray(formikValue) ? formikValue[0] : formikValue) ?? null;

    const { merchants = [], domains = [] } = initialValues;

    useFormRef({ formRef }, formik);

    useUserPivots({
        merchants,
        domains
    }, formik.setFieldValue);

    const onOptionChange = <T extends unknown>(
        fieldName: string,
        valueGetter = (newValue: any) => newValue
    ) => (_: SyntheticEvent | undefined, newValue: T) => {
        formik.setFieldValue(
            fieldName,
            Array.isArray(newValue)
                ? newValue
                : valueGetter(newValue),
            true
        );
        formik.setFieldTouched(fieldName, true, true);
    };

    const getRoleChangeValue = (roleOption: Option) => {
        setTimeout(() => {
            if (!isMerchantEnabled(
                bootstrapData.roles,
                user.roleName
            )({
                [FormField.RoleId]: roleOption?.id
            })) {
                onOptionChange(FormField.Merchants)(undefined, []);
            }
            if (!isMorEnabled(
                bootstrapData.roles,
                user.roleName
            )({
                [FormField.RoleId]: roleOption?.id
            })) {
                onOptionChange(FormField.Domains)(undefined, []);
            }
        });

        return roleOption?.id;
    };

    const sx = {
        mt: 2,
        ...TextFieldProps?.sx
    };

    const renderAction = () => {
        if (ActionComponent) {
            return (
                <ActionComponent
                    userFormikForm={formik}
                    userForm={props}
                />
            );
        }

        return (
            <LoadingButton
                type='submit'
                variant='contained'
                loading={
                    formik.isSubmitting ||
                    isLoading ||
                    isBootstrapDataLoading
                }
                disabled={FormValidator.from({
                    ...formik,
                    bootstrapData,
                    isBootstrapDataLoading
                }).isInvalid}
                {...getActionButtonProps?.(formik)}
                sx={{
                    mt: 3,
                    mb: 2,
                    justifySelf: 'flex-end',
                    ...getActionButtonProps?.(formik).sx
                }}
            >
                {actionLabel}
            </LoadingButton>
        );
    };

    return (
        <UserForm
            {...formik}
            actionSlot={renderAction()}
            rolesSlot={(
                <Autocomplete
                    fullWidth
                    disablePortal
                    size='medium'
                    layout={null}
                    loading={isBootstrapDataLoading}
                    disabled={!isRoleEnabled(user.roleName)}
                    options={bootstrapData.roles}
                    getOptionLabel={getOptionName}
                    value={getOptionById(bootstrapData.roles, formik.values[FormField.RoleId]) ?? null}
                    TextFieldProps={{
                        label: 'Role',
                        required: true,
                        ...TextFieldProps
                    }}
                    sx={sx}
                    {...AutocompleteProps}
                    onChange={onOptionChange(FormField.RoleId, getRoleChangeValue)}
                />
            )}
            merchantsSlot={(
                <Autocomplete
                    fullWidth
                    disablePortal
                    size='medium'
                    layout={null}
                    multiple={isMerchantMultiple(bootstrapData.roles, formik.values)}
                    disabled={!isMerchantEnabled(
                        bootstrapData.roles, user.roleName
                    )(
                        formik.values
                    )}
                    options={bootstrapData.merchants}
                    getOptionLabel={getOptionName}
                    value={getValue(
                        formik.values[FormField.Merchants],
                        isMerchantMultiple(bootstrapData.roles, formik.values)
                    )}
                    loading={isBootstrapDataLoading}
                    TextFieldProps={{
                        label: 'Merchants',
                        required: isMerchantRequired(bootstrapData.roles)(`${formik.values[FormField.RoleId]}`),
                        error: MerchantValidator.from({
                            ...formik,
                            bootstrapData,
                            isBootstrapDataLoading
                        }).isInvalid,
                        helperText: MerchantValidator.from({
                            ...formik,
                            bootstrapData,
                            isBootstrapDataLoading
                        }).message,
                        ...TextFieldProps
                    }}
                    sx={sx}
                    {...AutocompleteProps}
                    onChange={onOptionChange<
                        | Option
                        | Array<Option | undefined>
                        | undefined
                        | null
                    >(FormField.Merchants)}
                />
            )}
            domainsSlot={(
                <Autocomplete
                    fullWidth
                    disablePortal
                    multiple
                    size='medium'
                    layout={null}
                    disabled={!isMorEnabled(
                        bootstrapData.roles, user.roleName
                    )(
                        formik.values
                    )}
                    options={getDomainsOptions(
                        bootstrapData.roles, bootstrapData.domains
                    )(
                        formik.values
                    )}
                    getOptionLabel={getOptionName}
                    value={formik.values[FormField.Domains]}
                    loading={isBootstrapDataLoading}
                    TextFieldProps={{
                        label: 'MORs',
                        required: isDomainRequired(bootstrapData.roles)(`${formik.values[FormField.RoleId]}`),
                        ...TextFieldProps
                    }}
                    sx={sx}
                    {...AutocompleteProps}
                    onChange={onOptionChange(FormField.Domains)}
                />
            )}
            {...props}
        />
    );
};

export default memo(User);
