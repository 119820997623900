import type { CoreBankingEntity, Scalar } from "types";
import { MULTI_COLUMN_SEPARATOR } from "consts/filters";
import { BooleanSwitchValue } from "consts/general";
import { stringifyUrlQueryParams } from "./support";
import { Option } from "./option";

export const urlSearchparamTransformer = <T extends Object>(params: T) => {
    const stringifiedParams = Object.entries(params).reduce((urlSearchParams, [key, value]) => {
        if (Array.isArray(value)) {
            for (const paramValue of value) {
                urlSearchParams.append(key, paramValue);
            }
        } else if (typeof value !== 'undefined') {
            urlSearchParams.append(key, value);
        }

        return urlSearchParams;
    }, new URLSearchParams());

    return stringifyUrlQueryParams(stringifiedParams);
};

export const requestNullValueSanitizer = <T extends Object>(params: T) =>
    Object.entries(params).reduce((acc, [key, value]) => ({
        ...acc,
        [key]: value ?? undefined
    }), {});

export const requestFalsyValueSanitizer = <T extends object>(typeofTypes: Array<string | undefined | null> = []) => (params: T) => {
    const applySanitizer = <T extends unknown>(value: T) => {
        const checkTypeInvariant = () => typeofTypes.some(type => {
            if ([null, undefined].includes(value as null | undefined)) {
                return true;
            }

            return (
                typeof value === type
            );
        });
        const getValue = () => value || undefined;

        if (typeofTypes.length > 0) {
            return checkTypeInvariant()
                ? getValue()
                : value;
        }

        return getValue();
    };

    return Object.entries(params).reduce((acc, [key, value]) => ({
        ...acc,
        [key]: applySanitizer(value)
    }), {}) as T;
};

export const convertArrayToObject = <T extends string>(array: Array<T>, defaultValue = null) =>
    array.reduce((acc, property) => ({
        ...acc,
        [property]: defaultValue
    }), {});

export const convertArrayToMap = (array: Array<any>, indexField: string) =>
    array.reduce((accumulatedMap: Map<string, any>, currentArrayElement) =>
        accumulatedMap.set(
            currentArrayElement instanceof Object
                ? currentArrayElement[indexField]
                : currentArrayElement,
            currentArrayElement
        )
        , new Map<string, any>());

export const transformErrorResponseIntoMessage = (errorResponsePayload: Record<string, Array<string>>) =>
    Object.values(errorResponsePayload)
        .reduce((accMessage, currentError) => `
            ${accMessage}
            ${currentError.join()}
        `, '');

export const convertToFormData = (
    entity:
        | Array<[string, any]>
        | Record<string, any>,
    formElement?: HTMLFormElement
) => {
    const reducedEntity = Array.isArray(entity)
        ? entity
        : Object.entries(entity);
    return reducedEntity.reduce((formData: FormData, [key, value]) => {
        if (typeof value !== 'undefined') {
            formData.append(key, value);
        }
        return formData;
    }, new FormData(formElement));
};

export const convertToOptions = (
    valueOrRecord: string | Array<string> | Record<string, string>,
    transformStrategy?: (key: string, value?: string) => string
) => {
    if (Array.isArray(valueOrRecord)) {
        return valueOrRecord.map(key => Option.make(
            key,
            transformStrategy?.(key) ?? key
        ));
    }

    if (typeof valueOrRecord === 'object') {
        return Object.entries(valueOrRecord)
            .map(([key, value]) => Option.make(
                value,
                transformStrategy?.(key, value) ?? key
            ));
    }

    return Array.of(
        Option.make(
            valueOrRecord,
            transformStrategy?.(valueOrRecord) ?? valueOrRecord
        )
    );
};

export const fromCoreBankingCollectionToOptions = <T extends CoreBankingEntity>(
    coreBankingCollection: Array<T>,
    filterMiddlewareFn = (value: T, index?: number, array?: Array<T>) => true
) =>
    (key: keyof T) => (
        Array.from(
            new Set(
                coreBankingCollection
                    .filter(filterMiddlewareFn)
                    .map(({ [key]: value }) => value)
            ))
            .map(value => Option.make(String(value)))
    );

export const convertArrayToString = <T extends unknown>(
    array: Array<T>,
    separator = MULTI_COLUMN_SEPARATOR
) => array.filter(Boolean).join(separator);

export const convertToBooleanSwitch = <T extends Scalar<boolean>>(value: T) =>
    value
        ? BooleanSwitchValue.On
        : BooleanSwitchValue.Off;

export const converStringtoArray = <T extends string>(value?: T | T[]) => {
    if (!value) {
        return Array.of<T>();
    }

    return Array.isArray(value)
        ? value
        : value.split(',')
            .map(v => v.trim());
};
