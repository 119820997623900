import { RefundStrategy } from "consts/transactions";
import type { Transaction as TransactionType } from "features/transactions/types";

type Transaction = Pick<
    TransactionType,
    | 'status'
    | 'isEcommerce'
    | 'fundStatus'
    | 'paymentMethodName'
    | 'refundableAmount'
    | 'amountEur'
>;

const refundAssertions = new Map<RefundStrategy, (transaction: Transaction) => boolean>()
    .set(RefundStrategy.Partial, ({
        isEcommerce,
        ...transaction
    }) => Boolean(
        isEcommerce &&
        hasRefundableAmount(transaction)
    ))
    .set(RefundStrategy.Full, ({
        isEcommerce,
        ...transaction
    }) => Boolean(
        !isEcommerce &&
        hasRefundableAmount(transaction)
    ));

export default function useRefundActions(transaction: Transaction) {

    const getRefundStrategy = () => {
        try {
            refundAssertions.forEach((assertion, strategy) => {
                if (assertion(transaction)) {
                    throw strategy;
                }
            });
        } catch (strategy) {
            return strategy;
        }
    };

    const isPartialRefundAvailable = () => Object.is(
        getRefundStrategy(),
        RefundStrategy.Partial
    );

    const isFullRefundAvailable = () => Object.is(
        getRefundStrategy(),
        RefundStrategy.Full
    );

    const transactionRefundableAmount = (Number(transaction.amountEur) === Number(transaction.refundableAmount)) ? Number(transaction.amountEur) : Number(transaction.refundableAmount);

    return {
        getRefundStrategy,
        isPartialRefundAvailable,
        isFullRefundAvailable,
        transactionRefundableAmount
    };
}

function hasRefundableAmount({ refundableAmount }: Pick<Transaction, 'refundableAmount'>) {
    return Number(refundableAmount) > 0;
}
