import { memo } from 'react';
import StatefulTabs from 'ui/widgets/StatefulTabs';
import IncrementExchangeRates from './IncrementExchangeRates';
import Files from './Files';
import Increments from './Increments';
import IncrementTax from './IncrementTax';
import SummaryFiles from './SummaryFiles';
import FileTaxCalculations from './FileTaxCalculations';
import SettlementsImportHistory from './SettlementsImportHistory';
import useMerchantPayouts from './useMerchantPayouts';
import Calculations from './Calculations';
import IncrementsPivot from './IncrementsPivot';

const MerchantPayouts = () => {
    const {
        isMerchantPayoutsLoading,
        getTabValue,
        onChangeTab
    } = useMerchantPayouts();

    return (
        <StatefulTabs
            TabProps={{
                disabled: isMerchantPayoutsLoading
            }}
            model={[
                {
                    label: 'Summary Files',
                    component: (
                        <SummaryFiles />
                    )
                },
                {
                    label: 'Files',
                    component: (
                        <Files />
                    )
                },
                {
                    label: 'Calculations',
                    component: (
                        <Calculations />
                    )
                },
                {
                    label: 'Increments',
                    component: (
                        <Increments />
                    )
                },
                {
                    label: 'Increments Pivot',
                    component: (
                        <IncrementsPivot />
                    )
                },
                {
                    label: 'Files Tax Calc.',
                    component: (
                        <FileTaxCalculations />
                    )
                },
                {
                    label: 'Increments Taxes',
                    component: (
                        <IncrementTax />
                    )
                },
                {
                    label: 'Increment Exchange Rates',
                    component: (
                        <IncrementExchangeRates />
                    )
                },
                {
                    label: 'Settlements Import History',
                    component: (
                        <SettlementsImportHistory />
                    )
                }
            ]}
            value={getTabValue()}
            onChange={onChangeTab}
            TabListContainerProps={{
                borderBottom: 1,
                borderColor: 'divider'
            }}
            TabListProps={{
                'aria-label': "merchant payouts",
                variant: "scrollable",
                scrollButtons: "auto"
            }}
            TabPanelProps={{
                sx: {
                    padding: 1
                }
            }}
            ContainerProps={{
                pl: 1,
                sx: {
                    width: '100%'
                }
            }}
        />
    );
};

export default memo(MerchantPayouts);
