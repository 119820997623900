import type { LoadingButtonProps } from "@mui/lab/LoadingButton";
import type { Transaction } from "features/transactions/types";
import LoadingButton from "@mui/lab/LoadingButton";
import { memo, useState } from "react";
import Modal, { ModalProps } from "ui/atoms/Modal";
import Stack from "@mui/material/Stack";
import Button from "ui/atoms/Button";
import Typography from "@mui/material/Typography";
import useRefundActions from "./useRefundTransactions";
import { CurrencySymbol } from "consts/general";

type Props =
  & LoadingButtonProps
  & Pick<
    Transaction,
    | 'status'
    | 'isEcommerce'
    | 'fundStatus'
    | 'paymentMethodName'
    | 'refundableAmount'
    | 'amountEur'
  >
  & {
    readonly ModalProps: Partial<ModalProps>;
    readonly onProceed: () => Promise<void>;
  };

const RefundAction = ({ ModalProps, onProceed, loading, ...restProps }: Props) => {
  const [isOpen, setOpenState] = useState(false);
  const [isRefunded, setIsRefunded] = useState(false);
  const { isFullRefundAvailable, transactionRefundableAmount } = useRefundActions(restProps);

  const onOpen = () => {
    setOpenState(true);
  };

  const onClose = () => {
    setOpenState(false);
  };
  const handleProceed = () => {
    onProceed();
    setOpenState(false);
    setIsRefunded(true);
  };

  const checkIfRefundAvailable = () => isFullRefundAvailable();

  return (
    <>
      {checkIfRefundAvailable() && (
        <LoadingButton {...restProps} loading={loading} onClick={onOpen}>
          Refund
        </LoadingButton>
      )}

      <Modal
        {...ModalProps}
        isOpen={isOpen}
        titleSlot="Refund"
        onClose={onClose}
        actionsSlot={
          <>
            <Stack direction="row" justifyContent="flex-end">
              {!isRefunded && <Button onClick={handleProceed}>Proceed</Button>}
            </Stack>
            <Stack direction="row" justifyContent="flex-end">
              <Button onClick={onClose}>Close</Button>
            </Stack>
          </>
        }
        DialogContentProps={{dividers: false}}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {!isRefunded ?
            ` Do you want to proceed with refunding this transaction? ${transactionRefundableAmount}${CurrencySymbol.EUR} will be returned to the end user.`
            : "You have already refunded it!"}
        </Typography>
        
      </Modal>
    </>
  );
};

export default memo(RefundAction);
