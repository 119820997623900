import { ApiRoutes } from "consts/enpoints/api";
import { downloadFileApi, fetchApi, FormSerializers } from "infrastructure/api";
import type { ID } from "types";
import type {
  AbstractFileEntity,
  Files,
  IncrementExchangeRates,
  Increments,
  IncrementTaxes,
  ManageFileTaxes
} from "./types";

export const getMerchantPayoutSummaryFiles = (queryString: string) =>
  fetchApi(`${ApiRoutes.GetMerchantPayoutSummaryFiles}${queryString}`);

export const getMerchantPayoutFiles = (queryString: string) =>
  fetchApi(`${ApiRoutes.GetMerchantPayoutFiles}${queryString}`);

export const getEsMerchantPayoutFiles = (queryString: string) =>
  fetchApi(`${ApiRoutes.GetEsMerchantPayoutFiles}${queryString}`);

export const getMerchantPayoutFilesById = ({
  coreId,
  MOR
}: Pick<AbstractFileEntity, 'coreId' | 'MOR'>) =>
  fetchApi(ApiRoutes.GetMerchantPayoutFilesById(coreId), {
    headers: {
      MOR
    }
  });

export const getMerchantPayoutCalculations = (queryString: string) =>
  fetchApi(`${ApiRoutes.GetMerchantPayoutCalculations}${queryString}`);

export const getMerchantPayoutFileTaxCalculations = (queryString: string) =>
  fetchApi(`${ApiRoutes.GetMerchantPayoutFileTaxCalculations}${queryString}`);

export const createMerchantPayoutFileTaxCalculations = (body: ManageFileTaxes) =>
  fetchApi(ApiRoutes.PostMerchantPayoutFileTaxCalculations(), {
    method: 'POST',
    body: {
      ...body,
      coreId: undefined
    }
  });

export const updateMerchantPayoutFileTaxCalculations = (body: Partial<ManageFileTaxes>) =>
  fetchApi(ApiRoutes.PostMerchantPayoutFileTaxCalculations(body.merchantPayoutFileId), {
    method: 'PATCH',
    body
  });

export const deleteMerchantPayoutFileTaxCalculations = (coreId: string) =>
  fetchApi(ApiRoutes.DeleteMerchantPayoutFileTaxCalculations(coreId), {
    method: 'DELETE'
  });

export const getTransactionsMerchantPayoutIncrementsPivot = (queryString: string) =>
  fetchApi(`${ApiRoutes.GetTransactionsMerchantPayoutIncrementsPivot}${queryString}`);

export const getMerchantPayoutIncrementTaxes = (queryString: string) =>
  fetchApi(`${ApiRoutes.GetMerchantPayoutIncrementTaxes}${queryString}`);

export const postMerchantPayoutIncrementTaxes = (body: Partial<IncrementTaxes>) =>
  fetchApi(ApiRoutes.PostMerchantPayoutIncrementTaxes, {
    method: "POST",
    body,
  });

export const putMerchantPayoutIncrementTaxes = ({ coreId, ...body }:
  & Partial<Omit<IncrementTaxes, 'coreId'>>
  & Pick<IncrementTaxes, 'coreId'>
) =>
  fetchApi(ApiRoutes.PutMerchantPayoutIncrementTaxes(coreId), {
    method: "PUT",
    body
  });

export const deleteMerchantPayoutIncrementTaxes = ({
  coreId
}: Pick<IncrementTaxes, 'coreId'>) =>
  fetchApi(ApiRoutes.DeleteMerchantPayoutIncrementTaxes(coreId), {
    method: "DELETE",
  });
export const getMerchantPayoutIncrements = (queryString: string) =>
  fetchApi(`${ApiRoutes.GetMerchantPayoutIncrements}${queryString}`);

export const putMerchantPayoutIncrements = (body: Partial<Increments>) =>
  fetchApi(ApiRoutes.PutMerchantPayoutIncrements(body.coreId!), {
    method: "PUT",
    body
  });

export const getMerchantPayoutIncrementExchangeRates = (queryString: string) =>
  fetchApi(`${ApiRoutes.GetMerchantPayoutIncrementExchangeRates}${queryString}`);

export const postMerchantPayoutIncrementExchangeRates = (body: Partial<IncrementExchangeRates>) =>
  fetchApi(ApiRoutes.PostMerchantPayoutIncrementExchangeRates, {
    method: "POST",
    body,
  });

export const deleteMerchantPayoutIncrementExchangeRates = ({
  coreId,
  MOR
}: Pick<IncrementExchangeRates, 'coreId' | 'MOR'>) =>
  fetchApi(ApiRoutes.DeleteMerchantPayoutIncrementExchangeRates(coreId), {
    method: "DELETE",
    headers: {
      MOR
    }
  });

export const postMerchantPayoutIncrementExchangeRatesBulk = (body: FormData) =>
  fetchApi(ApiRoutes.PostMerchantPayoutIncrementExchangeRatesBulk, {
    serializer: FormSerializers.FormData,
    method: "POST",
    body,
  });

export const postMerchantPayoutIncrementTaxesBulk = (body: FormData) =>
  fetchApi(ApiRoutes.PostMerchantPayoutIncrementTaxesBulk, {
    serializer: FormSerializers.FormData,
    method: "POST",
    body,
  });

export const downloadIncremetExchangeRatesCSVTemplate = (fileName: string) =>
  downloadFileApi(ApiRoutes.PostMerchantPayoutIncrementExchangeRatesBulk, {
    headers: {
      Accept: "text/csv",
    },
    fileName
  });

export const downloadIncremetTaxesCSVTemplate = (fileName: string) =>
  downloadFileApi(ApiRoutes.PostMerchantPayoutIncrementTaxesBulk, {
    headers: {
      Accept: "text/csv",
    },
    fileName
  });

export const postMerchantPayoutFiles = (body: FormData) =>
  fetchApi(ApiRoutes.PostMerchantPayoutFiles, {
    serializer: FormSerializers.FormData,
    method: "POST",
    body,
  });

export const putMerchantPayoutFiles = ({ coreId, ...body }: Files) =>
  fetchApi(ApiRoutes.PutMerchantPayoutFiles(coreId), {
    method: "PUT",
    body
  });

export const getMerchantPayoutFilesSettlementXLS = (fileKey: string, fileName: string) =>
  downloadFileApi(ApiRoutes.GetFile(fileKey), {
    headers: {
      Accept: "application/xls",
    },
    fileName
  });
export const previewMerchantPayoutFilesSettlementXLS = ({
  coreId,
  MOR
}: Pick<AbstractFileEntity, 'coreId' | 'MOR'>, fileName: string) =>
  downloadFileApi(ApiRoutes.PreviewMerchantPayoutFilesSettlementXLS(coreId), {
    headers: {
      Accept: "application/xls",
      MOR
    },
    fileName
  });
export const getMerchantPayoutFilesTransactionCSV = ({
  coreId,
  MOR
}: Pick<AbstractFileEntity, 'coreId' | 'MOR'>, fileName: string) =>
  downloadFileApi(ApiRoutes.GetMerchantPayoutFilesTransactionsCSV(coreId), {
    headers: {
      Accept: "text/csv",
      MOR
    },
    fileName
  });

export const getMerchantPayoutSummaryFilesTransactionCSV = ({
  coreId,
  MOR
}: Pick<AbstractFileEntity, 'coreId' | 'MOR'>, fileName: string) =>
  downloadFileApi(ApiRoutes.GetMerchantPayoutSummaryFilesTransactionsCSV(coreId), {
    headers: {
      Accept: "text/csv",
      MOR
    },
    fileName
  });

export const getMerchantPayoutFilesStillPendingSettlementCSV = (coreId: ID, fileName: string) =>
  downloadFileApi(ApiRoutes.GetMerchantPayoutFilesStillPendingSettlementCSV(coreId), {
    headers: {
      Accept: "text/csv",
    },
    fileName
  });

export const generateMerchantPayoutFilesSettlements = ({
  coreId,
  MOR
}: Pick<AbstractFileEntity, 'coreId' | 'MOR'>) =>
  fetchApi(ApiRoutes.GetMerchantPayoutFilesById(coreId), {
    method: "PATCH",
    headers: {
      Action: "dispatchExcelGeneration",
      MOR
    },
  });

export const deleteMerchantPayoutFiles = ({
  coreId,
  MOR
}: Pick<AbstractFileEntity, 'coreId' | 'MOR'>) =>
  fetchApi(ApiRoutes.DeleteMerchantPayoutFiles(coreId), {
    method: "DELETE",
    headers: {
      MOR
    }
  });

export const deleteMerchantPayoutSummaryFiles = ({
  coreId,
  MOR
}: Pick<AbstractFileEntity, 'coreId' | 'MOR'>) =>
  fetchApi(ApiRoutes.DeleteMerchantPayoutSummaryFiles(coreId), {
    method: "DELETE",
    headers: {
      MOR
    }
  });

export const mergeMerchantPayoutFiles = (body: FormData, route = ApiRoutes.PostMerchantPayoutFiles) =>
  fetchApi(route, {
    serializer: FormSerializers.FormData,
    method: "POST",
    body,
  });

export const postMerchantPayoutSummaryFiles = (body: FormData) =>
  fetchApi(ApiRoutes.PostMerchantPayoutSummaryFiles, {
    serializer: FormSerializers.FormData,
    method: "POST",
    body,
  });

export const getSettlementsImportHistory = (queryString = '') =>
  fetchApi(`${ApiRoutes.GetSettlementsImportHistory}${queryString}`);
