import { memo } from "react";
import { useTypedSelector } from "hooks";
import { filterTypes } from "consts/merchantPayouts";
import { TableGridMode } from "consts/table";
import { selectMerchantPayoutIncrementsVia } from "features/general/selectors";
import useMerchantPayoutsActions from "features/merchantPayouts/useActions";
import MerchantPayoutsTable from "../MerchantPayoutsTable";

const Calculations = () => {
    const merchantPayoutIncrementsVia = useTypedSelector(selectMerchantPayoutIncrementsVia);
    const {
        getMerchantPayoutCalculations
    } = useMerchantPayoutsActions();

    return (
        <MerchantPayoutsTable
            mode={TableGridMode.MerchantPayoutCalculations}
            filterTypes={filterTypes}
            fetchTableData={getMerchantPayoutCalculations}
            FiltersProps={{
                filtersModelOverrides: {
                    Via: merchantPayoutIncrementsVia
                }
            }}
        />
    );
};

export default memo(Calculations);
