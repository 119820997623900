import { useCallback, useState } from 'react';
import type { Moment } from 'moment';
import type { DateRange } from '@mui/x-date-pickers-pro-v.6';
import { useMui } from 'hooks';
import type { AbstractActionableFilterProps } from 'ui/widgets/Filters/types';

type UseDateFilter = AbstractActionableFilterProps & {
    readonly id: string;
    readonly value: Moment | DateRange<Moment> | null;
};

export default function useDateFilter({ id, value, onChange }: UseDateFilter) {
    const [isOpen, setOpen] = useState<boolean>(false);

    useMui({
        deps: [isOpen],
        timeout: 50
    });

    const openPopup = useCallback(() => setOpen(true), []);

    const closePopup = useCallback(() => setOpen(false), []);

    const onApply = useCallback(() => setOpen(false), []);

    const handleChange = (payload: UseDateFilter['value']) =>
        onChange({
            type: id,
            payload
        });

    const onClear = () =>
        handleChange(
            Array.isArray(value)
                ? [null, null]
                : null
        );

    return {
        isOpen,
        openPopup,
        closePopup,
        onApply,
        onClear,
        handleChange
    };
};
