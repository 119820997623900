export const enum TableGridMode {
    Transaction,
    TransactionList,
    Merchant,
    BankSdk,
    User,
    Consumer,
    MerchantPayoutsSummaryFiles,
    MerchantPayoutsFiles,
    MerchantPayoutCalculations,
    MerchantPayoutFileTaxCalculations,
    TransactionsMerchantPayoutIncrementsPivot,
    MerchantPayoutIncrementTaxes,
    MerchantPayoutIncrements,
    MerchantPayoutIncrementExchangeRates,
    ManualBulkKYCsImportHistory,
    MerchantUser,
    MerchantLedger,
    BlacklistedConsumer,
    WhitelistedConsumer,
    ActiveConsumer,
    MerchantPayoutsSettlementsImportHistory
}

export const DEFAULT_PAGE_SIZE = 100;

export const ROWS_PER_PAGE_OPTIONS = [
    DEFAULT_PAGE_SIZE,
    DEFAULT_PAGE_SIZE * 5,
    DEFAULT_PAGE_SIZE * 10
];

export const maxSizeSlice = 100;

export const sortModelQueryField = 'sort';

export const columnVisibilityQueryField = 'c';

export const summsQueryField = 'sums[]';

export const allSummableColumnsAlias = '*';

export const Paginate = {
    page: 'page',
    perPage: 'perPage'
};

export const sort = [
    sortModelQueryField
];

export const visible = [
    columnVisibilityQueryField
];

export const paginate = [
    Paginate.page,
    Paginate.perPage
];

export const summable = [
    summsQueryField
];

export const defaultValues = [
    [Paginate.perPage, DEFAULT_PAGE_SIZE] as const
];
