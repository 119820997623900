import type { PaginateResourceResponse } from "types";
import type { RootState } from "infrastructure/store";
import { DEFAULT_PAGE_SIZE, ROWS_PER_PAGE_OPTIONS } from "consts/table";
import { useTypedSelector } from "hooks";

const defaultPaginateOptions = {
    current_page: 1,
    per_page: DEFAULT_PAGE_SIZE,
    total: DEFAULT_PAGE_SIZE
};

export type UsePaginator = {
    readonly selectTableDataSlice: (state: RootState) => PaginateResourceResponse<any>['data'];
    readonly rowsPerPageOptions?: Array<number>;
    readonly paginateOptions?: Pick<
        PaginateResourceResponse<null>['data'],
        | 'current_page'
        | 'per_page'
        | 'total'
    >;
};

export default function usePaginator({
    selectTableDataSlice,
    paginateOptions = defaultPaginateOptions,
    rowsPerPageOptions = ROWS_PER_PAGE_OPTIONS
}: UsePaginator) {
    const tableDataSlice = useTypedSelector(selectTableDataSlice);

    const {
        data = [],
        current_page: currentPage = paginateOptions.current_page,
        per_page: perPage = paginateOptions.per_page,
        total = paginateOptions.total,
        sortable
    } = tableDataSlice ?? {};

    return {
        data,
        currentPage,
        total,
        sortable,
        perPage: withPerPageValidation({
            perPage,
            rowsPerPageOptions
        })
    };
}

function withPerPageValidation({
    perPage,
    rowsPerPageOptions = []
}: Pick<UsePaginator, 'rowsPerPageOptions'> & {
    readonly perPage: number;
}) {
    for (const rowsPerPageOption of rowsPerPageOptions) {
        if (perPage === rowsPerPageOption) {
            return perPage;
        }

        if (perPage > rowsPerPageOption) {
            continue;
        }

        if (perPage < rowsPerPageOption) {
            return Math.max(perPage, rowsPerPageOption);
        }
    }

    return perPage;
};
