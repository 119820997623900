import { memo } from "react";
// TODO: Uncomment when the checkbox selection is needed
// import { GRID_CHECKBOX_SELECTION_FIELD, type GridSelectionModel } from "@mui/x-data-grid";
// import { useTypedSelector } from "hooks";
import { TableGridMode } from "consts/table";
import { Filters, FilterVariant } from "consts/filters";
import { Filters as MerchantPayoutsFilters } from "consts/merchantPayouts";
import useMerchantPayoutsActions from "features/merchantPayouts/useActions";
// import { selectIsMerchantPayoutEntityLoading } from "features/merchantPayouts/selectors";
import MerchantPayoutsTable from "../MerchantPayoutsTable";

const SettlementsImportHistory = () => {
    // const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

    // const isMerchantPayoutsSettlementsImportHistoryLoading = useTypedSelector(selectIsMerchantPayoutEntityLoading);

    const { getSettlementsImportHistory } = useMerchantPayoutsActions();

    // const isRowSelectable = () => !isMerchantPayoutsSettlementsImportHistoryLoading;

    return (
        <MerchantPayoutsTable
            // disableSelectionOnClick
            // checkboxSelection
            // isRowSelectable={isRowSelectable}
            // selectionModel={selectionModel}
            // onSelectionModelChange={setSelectionModel}
            // initialState={{
            //     pinnedColumns: {
            //         left: [GRID_CHECKBOX_SELECTION_FIELD]
            //     }
            // }}
            mode={TableGridMode.MerchantPayoutsSettlementsImportHistory}
            fetchTableData={getSettlementsImportHistory}
            filterTypes={new Map<FilterVariant, string[]>([
                [FilterVariant.DateRange, [Filters.createdAt]],
                [FilterVariant.MultiOption, [MerchantPayoutsFilters.PSP]],
                [FilterVariant.Text, [MerchantPayoutsFilters.fileKey]]
            ])}
        />
    );
};

export default memo(SettlementsImportHistory);
