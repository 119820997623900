import { useEffect, useRef } from 'react';
import { useTypedSelector } from 'hooks';
import { merchantPayoutTabValueMapping } from 'consts/merchantPayouts';
import { TabManager } from 'util/tab';
import useMerchantActions from "features/merchants/useMerchantActions";
import usePivotActions from "features/pivots/useActions";
import useMerchantPayoutActions from "features/merchantPayouts/useActions";
import { selectIsMerchantPayoutLoading, selectMerchantPayoutsActiveTab } from 'features/merchantPayouts/selectors';

export default function useMerchantPayouts() {
    const activeTab = useTypedSelector(selectMerchantPayoutsActiveTab);
    const isMerchantPayoutsLoading = useTypedSelector(selectIsMerchantPayoutLoading);

    const { getAllMerchants } = useMerchantActions();
    const { getAllMerchantsMethods } = usePivotActions();

    const { onChangeTab } = useMerchantPayoutActions();

    const initRef = useRef({
        getAllMerchants,
        getAllMerchantsMethods
    });

    useEffect(() => {
        const {
            getAllMerchants,
            getAllMerchantsMethods
        } = initRef.current;

        getAllMerchants();
        getAllMerchantsMethods();
    }, []);

    const getTabValue = () =>
        TabManager.getTabValue(merchantPayoutTabValueMapping, activeTab);

    return {
        isMerchantPayoutsLoading,
        getTabValue,
        onChangeTab
    };
};
